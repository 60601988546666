// Candidates Api settings
let candidatesApiHost = process.env.VUE_APP_CANDIDATES_API_HOST;
let candidatesApiBasePath = process.env.VUE_APP_CANDIDATES_API_PATH;
let candidatesApiBaseHeaders = {'Content-Type': 'application/x-www-form-urlencoded'};

// Candidates Api settings
let rsvpApiHost = process.env.VUE_APP_RSVP_API_HOST;
let rsvpApiBasePath = process.env.VUE_APP_RSVP_API_PATH;
let rsvpApiBaseHeaders = {'Content-Type': 'application/x-www-form-urlencoded'};

// Jury Api
let juryApiHost = process.env.VUE_APP_JURY_API_HOST;
let juryApiBasePath = process.env.VUE_APP_JURY_API_PATH;
let juryApiBaseHeaders = {'Content-Type': 'application/x-www-form-urlencoded'};

export const juryApiSettings = {
    host: juryApiHost,
    basePath: juryApiBasePath,
    defaultHeaders: juryApiBaseHeaders,
};

export const candidatesApiSettings = {
    host: candidatesApiHost,
    basePath: candidatesApiBasePath,
    defaultHeaders: candidatesApiBaseHeaders,
};

export const rsvpApiSettings = {
    host: rsvpApiHost,
    basePath: rsvpApiBasePath,
    defaultHeaders: rsvpApiBaseHeaders,
};
