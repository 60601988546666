<template>
    <div>
        <transition name="fade">
            <div class="err-message" v-if="msgError && msgError.length > 0">

                <a href="#" @click.prevent="dismiss" class="dismiss">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                     width="12" height="12" x="0px" y="0px"
                     viewBox="0 0 1000 1000"
                     enable-background="new 0 0 1000 1000"
                     xml:space="preserve">
                    <g><path d="M990,928.8L561.3,500L990,71.3L928.8,10L500,438.8L71.3,10L10,71.3L438.8,500L10,928.8L71.3,990L500,561.3L928.8,990L990,928.8z"/></g>
                    </svg>
                </a>
                Ops! {{ msgError }}
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        computed : {
            msgError() {
                return this.$store.state.error;
            }
        },
        methods: {
            dismiss() {
                this.$store.commit('setError', '');
            }
        }
    }
</script>

<style lang="scss">
    .err-message {
        position: fixed;
        left: 1em;
        right: 1em;
        bottom: 1em;
        padding: 1em;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        z-index: 1000;
        color: white;
        background-color: #cb0a1e;
        border: 4px solid fade_out(white, .4);
        box-shadow: 0 0 20px fade_out(black, .3);
    }
    .dismiss {
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
        fill: white;
        font-weight: bold;
        &:hover {
            color: white;
            fill: white;
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: bottom .5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        bottom: -100px;
    }
</style>
