<template>
    <div class="">
        <social-sharing
            :url="url"
            :title="title"
            :description="description"
            :hashtags="hashtags"
            inline-template>

            <ul class="flex flex-wrap items-center space-x-4">
                <li>
                    <!-- Facebook -->
                    <network network="facebook">
                        <button type="button"
                                class="transition-all duration-300 opacity-75 hover:opacity-100 hover:text-white">
                            <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"
                                 style="enable-background:new 0 0 40 40" xml:space="preserve"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="-277.375" y1="406.602" x2="-277.375" y2="407.573" gradientTransform="matrix(40 0 0 -39.7778 11115.001 16212.334)"><stop offset="0" style="stop-color:#0062e0"/><stop
                                offset="1" style="stop-color:#19afff"/></linearGradient>
                                <path
                                    d="M16.7 39.8C7.2 38.1 0 29.9 0 20 0 9 9 0 20 0s20 9 20 20c0 9.9-7.2 18.1-16.7 19.8l-1.1-.9h-4.4l-1.1.9z"
                                    style="fill:url(#a)"/>
                                <path
                                    d="m27.8 25.6.9-5.6h-5.3v-3.9c0-1.6.6-2.8 3-2.8H29V8.2c-1.4-.2-3-.4-4.4-.4-4.6 0-7.8 2.8-7.8 7.8V20h-5v5.6h5v14.1c1.1.2 2.2.3 3.3.3 1.1 0 2.2-.1 3.3-.3V25.6h4.4z"
                                    style="fill:#fff"/></svg>
                        </button>
                    </network>
                </li>
                <li>
                    <!-- Twitter -->
                    <network network="twitter">
                        <button type="button"
                                class="transition-all duration-300 opacity-75 hover:opacity-100 hover:text-white">
                            <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.505 139.378">
                                <path style="fill:#2aa9e0"
                                      d="M453.826 412.806a70.337 70.337 0 0 1-20.209 5.54c7.265-4.354 12.844-11.25 15.471-19.466a70.437 70.437 0 0 1-22.345 8.538 35.136 35.136 0 0 0-25.683-11.11c-19.431 0-35.186 15.753-35.186 35.185 0 2.757.31 5.443.911 8.018-29.243-1.467-55.17-15.475-72.525-36.764a35.019 35.019 0 0 0-4.764 17.69c0 12.207 6.212 22.977 15.653 29.287a35.047 35.047 0 0 1-15.937-4.401c-.004.146-.004.294-.004.442 0 17.048 12.13 31.268 28.226 34.503a35.224 35.224 0 0 1-15.89.603c4.478 13.978 17.472 24.151 32.87 24.434-12.043 9.438-27.214 15.064-43.7 15.064-2.84 0-5.64-.168-8.393-.493 15.571 9.985 34.067 15.81 53.937 15.81 64.72 0 100.113-53.616 100.113-100.114 0-1.526-.034-3.043-.102-4.553a71.483 71.483 0 0 0 17.556-18.213z"
                                      transform="translate(-282.32 -396.307)"/>
                            </svg>
                        </button>
                    </network>
                </li>
                <li v-if="isMobileRequest">
                    <!-- Whatsapp -->
                    <network network="whatsapp">
                        <button type="button"
                                class="transition-all duration-300 opacity-75 hover:opacity-100 hover:text-white">
                            <svg class="fill-current w-12 h-12 icon icon-whatsapp"
                                 aria-labelledby="simpleicons-whatsapp-icon" role="img"
                                 viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <title id="simpleicons-whatsapp-icon">WhatsApp</title>
                                <path
                                    d="M17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015-.2 0-.523.074-.797.359-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885M20.52 3.449C18.24 1.245 15.24 0 12.045 0 5.463 0 .104 5.334.101 11.893c0 2.096.549 4.14 1.595 5.945L0 24l6.335-1.652c1.746.943 3.71 1.444 5.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411"/>
                            </svg>
                        </button>
                    </network>
                </li>
            </ul>
        </social-sharing>

        <div v-if="showLink" class="text-center w-full mt-4">
            <span class="link-label">Condividi il link!</span><br>
            <input :value="url"
                   class="w-full bg-transparent border-b-2 border-white mt-2 px-4 py-2 text-xs"
                   readonly
                   @change="disableInput"
                   @click="selectAll">
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'url',
        'title',
        'description',
        'isMobileRequest',
        'showLink',
        'additionalHashTags'
    ],
    data() {
        return {
            defaultHashtags: [
                'rockol',
                'rockolawards' + process.env.VUE_APP_AWARDS_YEAR,
                'vota'
            ],
        }
    },
    computed: {
        hashtags() {
            let hashtags = this.defaultHashtags.slice(0);
            if ((this.additionalHashTags || []).length > 0) {
                this.additionalHashTags.forEach((x) => {
                    if (x) {
                        hashtags.unshift(x);
                    }
                })
            }
            return hashtags.join(',');
        }
    },
    methods: {
        disableInput(event) {
            event.target.value = this.url;

        },
        selectAll(event) {
            event.target.select();
        }
    }
}
</script>

<style lang="scss">

.icon-facebook {
    color: #3b5998;
}

.icon-twitter {
    color: #00acee;
}

.icon-whatsapp {
    color: #25D366;
}

</style>
