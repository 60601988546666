<template>
    <modal-view @close-panel="closePanel" :open="showPanel" v-if="showPanel">
        <div class="h-full w-full p-8 max-w-5xl">
            <div v-if="isLoading" class="w-full h-full flex flex-col items-center justify-center">
                <h1 class="font-bold">Caricamento in corso</h1>
                <svg-icon icon="loading" width="150" height="150" cssClass="white-icon"></svg-icon>
            </div>
            <div v-else>
                <record-detail
                    :candidateData="candidateData"
                    v-if="recordType"></record-detail>
                <artist-show-detail
                    :candidateData="candidateData"
                    v-if="artistType"></artist-show-detail>
                <video-detail
                    :candidateData="candidateData"
                    v-if="videoType"></video-detail>
                <creator-detail
                    :candidateData="candidateData"
                    v-if="creatorType"></creator-detail>
            </div>
        </div>
    </modal-view>
</template>

<script>
import RecordDetail from '../records/RecordDetail.vue'
import ArtistShowsDetail from '../artists/ArtistShowsDetail.vue'
import VideoDetail from '../videos/VideoDetail.vue'
import CreatorDetail from '../creators/CreatorDetail.vue'
import SvgIcon from '../../shared/SvgIcon.vue';

import {
    ARTIST_CANDIDATE_TYPE,
    CREATOR_CANDIDATE_TYPE,
    RECORD_CANDIDATE_TYPE,
    VIDEO_CANDIDATE_TYPE
} from '../../../shared/common';
import ModalView from "../../page-components/ModalView";

export default {
    created: function () {
        const vm = this;
        window.addEventListener('keyup', function (e) {
            if (e.key === "Escape") {
                vm.$store.commit('closeCandidatePanel');
            }
        });
    },
    watch: {
        isLoading(loading) {
            if (!loading) {
                this.$root.$emit('show-candidate-detail-panel')
            }
        }
    },
    components: {
        ModalView,
        'record-detail': RecordDetail,
        'video-detail': VideoDetail,
        'creator-detail': CreatorDetail,
        'artist-show-detail': ArtistShowsDetail,
        'svg-icon': SvgIcon
    },
    data() {
        return {
        }
    },
    computed: {
        artistType() {
            return this.type === ARTIST_CANDIDATE_TYPE;
        },
        recordType() {
            return this.type === RECORD_CANDIDATE_TYPE;
        },
        videoType() {
            return this.type === VIDEO_CANDIDATE_TYPE;
        },
        creatorType() {
            return this.type === CREATOR_CANDIDATE_TYPE;
        },
        isLoading() {
            return this.$store.getters.candidateDetailPanel.loading;
        },
        detailPanel() {
            return this.$store.getters.candidateDetailPanel;
        },
        type() {
            return this.detailPanel.candidateType;
        },
        candidateData() {
            return this.detailPanel.candidateData;
        },
        showPanel() {
            return this.$store.getters.candidateDetailPanel.show;
        },
    },
    methods: {
        closePanel() {
            this.$store.commit('closeCandidatePanel');
        }
    }
}
</script>
