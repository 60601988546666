import sendErrorToSentry from "../../shared/errorReporting";

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

export const login = {
    namespaced: true,
    state: {
        loginForm: {
            onBoarding: false,
            submitted: false,
            isSubmitting: false,
            authErrors: [],
            model: {
                username: '',
                password: '',
            }
        }
    },
    mutations: {
        setUsername(state, username) {
            state.loginForm.model.username = username;
        },
        setOnboarding(state, onBoarding) {
            state.loginForm.onBoarding = onBoarding;
        },
        resetForm(state) {
            state.loginForm.model.username = '';
            state.loginForm.model.password = '';
            state.loginForm.onBoarding = false;
            state.loginForm.submitted = false;
            state.loginForm.isSubmitting = false;
            state.loginForm.authErrors = [];
        },
        loginFormAddAuthError(state, errorMessage) {
            state.loginForm.authErrors.push(errorMessage)
        },
        loginFormResetAuthError(state) {
            state.loginForm.authErrors = [];
        },
        loginFormPrepareSubmit(state) {
            state.loginForm.submitted = true;
            state.loginForm.isSubmitting = true;
            state.loginForm.authErrors = [];
        },
        loginFormSubmitDone(state) {
            state.loginForm.isSubmitting = false;
        }
    },
    actions: {
        async sendLoginLink(state, {userEmail, userId}) {
            const continueUrl = (process.env.VUE_APP_HOST +
                process.env.VUE_APP_RELATIVE_PATH +
                '/accesso-giuria?u=' + userId +
                '&context=awards').replace(/([^:]\/)\/+/g, "$1");
            const actionCodeSettings = {
                url: continueUrl,
                handleCodeInApp: true,
            };

            try {
                await firebase.auth().sendSignInLinkToEmail(userEmail, actionCodeSettings);
            } catch (err) {
                sendErrorToSentry(err);
                return Promise.reject(err);
            }
        },
        loginJuror({commit, dispatch, getters}) {
            const loginForm = getters.loginForm;
            commit('loginFormPrepareSubmit');
            commit('loginFormResetAuthError');
            if (getters.loginFormErrors.length === 0) {
                dispatch(
                    'userSignIn',
                    {
                        email: loginForm.model.username,
                        password: loginForm.model.password,
                        onboarding: loginForm.onBoarding
                    },
                    {root: true}
                ).then((result) => {
                    commit('loginFormSubmitDone');
                    if (result.error && result.message) {
                        commit('loginFormAddAuthError', result.message);
                    } else {
                        dispatch('closeAllModals', null, {root: true});
                        commit('resetForm');
                        commit('closeJuryLoginScreen', null, {root: true})
                    }

                });
            } else {
                commit('loginFormSubmitDone');
            }
        }
    },
    getters: {
        loginForm(state) {
            return state.loginForm;
        },
        loginFormErrors(state) {
            let errors = [];

            state.loginForm.authErrors.forEach((err) => errors.push(err));

            if (state.loginForm.model.username.length === 0) {
                errors.push("L'indirizzo e-mail non può essere vuoto");
            }

            if (state.loginForm.model.password.length === 0) {
                errors.push("Password non può essere vuoto");
            }

            return errors;
        }
    }
};