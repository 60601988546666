export const getters = {
    appTitle(state) {
        return state.app.title;
    },
    getUser(state) {
        return state.user || null
    },
    userDisplayName(state, getters) {
        const user = getters.getUser;
        if (!user) {
            return null;
        }
        if (user.displayName) {
            return user.displayName;
        }
        if (user.firstName && user.lastName) {
            return user.firstName + ' ' + user.lastName
        }

        return null;
    },
    getUserConsents(state) {
        return (state.user || {}).consents || {}
    },
    userNeedConsents(state, getters) {
        const consents = getters.getUserConsents;
        const privacy = consents.privacy || false;
        const awards = consents.awards || false;
        return state.userLoggedIn && !state.loggingIn && (
            !privacy || !awards
        );
    },
    getCurrentPoll(state) {
        return state.currentPoll
    },
    loggedInUserIsJuror(state, getters) {
        return getters.userLoggedIn && state.user && state.user.isJuror;
    },
    isVoteOpen(state, getters) {
        return getters.isVoteOpenForUsers || getters.isVoteOpenForJury;
    },
    isVoteClosed(state, getters) {
        return !getters.isVoteOpen;
    },
    voteForUserHasNotBeenStarted(state) {
        return state.app.currentTimestamp < state.app.voteDatesForUsers.start
            && state.app.currentTimestamp < state.app.voteDatesForUsers.end;
    },
    voteForUserHasEnded(state) {
        return state.app.currentTimestamp > state.app.voteDatesForUsers.end;
    },
    isVoteOpenForUsers(state) {
        return state.app.currentTimestamp >= state.app.voteDatesForUsers.start &&
            state.app.currentTimestamp <= state.app.voteDatesForUsers.end;
    },
    isVoteOpenForJury(state) {
        return state.app.currentTimestamp >= state.app.voteDatesForJury.start &&
            state.app.currentTimestamp <= state.app.voteDatesForJury.end;
    },
    currentPollCode(state) {
        return state.currentPoll.code
    },
    currentPollTitle(state) {
        return state.currentPoll.title
    },
    temporaryLogin(state) {
        return state.temporaryLogin
    },
    userLoggedIn(state) {
        return state.userLoggedIn
    },
    loginCheckInProgress(state) {
        return state.loginCheckInProgress
    },
    getError(state) {
        return state.error
    },
    getLoading(state) {
        return state.loading
    },
    loginPanel(state) {
        return state.loginPanel
    },
    voteAfterLogin(state) {
        return state.voteAfterLogin
    },
    voteInProgress(state) {
        return state.voteInProgress
    },
    userPanel(state) {
        return state.userPanel
    },
    sharePanel(state) {
        return state.sharePanel
    },
    candidateDetailPanel(state) {
        return state.candidateDetailPanel
    },
    votePanel(state) {
        return state.votePanel
    },
    juryLoginScreen(state) {
        return state.juryLoginScreen
    },
    juryOnboardScreen(state) {
        return state.juryOnboardScreen
    },
    userVoteState(state) {
        return state.userVoteState;
    },
    hasAlreadyVotedForThisPoll(state) {
        const pollName = state.currentPoll.code;
        return state.userVoteState.pollsVoted.indexOf(pollName) !== -1;
    },
    pollsToVoteList(state) {
        const polls = state.userVoteState.polls;

        const list = state.userVoteState.pollsStillToBeVoted
            .map((pollCode) => {
                const obj = {
                    code: pollCode,
                    routeName: pollCode,
                    title: polls[pollCode].title,
                };
                return obj;
            });
        return list;
    },
    withFooter(state) {
        return state.app.useFooter
            ? 'with-footer'
            : '';
    }
};
