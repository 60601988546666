<template>
    <modal-view @close-panel="closePanel" :open="showLoginPanel" v-if="showLoginPanel">
        <div>
            <div>
                <div class="flex flex-col space-y-2">
                    <div class="flex flex-col text-white justify-center items-center" v-if="$store.state.loggingIn">
                        <h2 class="font-black text-3xl text-white">Accesso in corso</h2>
                        <svg-icon icon="loading" :width="100" :height="100" cssClass="fill-current"></svg-icon>
                    </div>
                    <div class=" flex flex-col space-y-2" v-if="!$store.state.loggingIn">
                        <div class="text-center">
                            <section v-if="!isVoteOpenForUsers">
                                <p>
                                    Le votazioni per il pubblico sono aperte <br>
                                    dal <strong class="text-success">{{ userStartDate }}</strong> al
                                    <strong class="text-success">{{ userEndDate }}</strong>.
                                </p>
                            </section>
                            <section v-if="isVoteOpenForUsers">
                                <div v-if="voteAfterLogin">
                                    <p class="big">
                                        Stai per votare<br>
                                        <span class="font-semibold text-4xl text-white">{{ candidateTitle }}</span><br>
                                        nella categoria <strong>{{ pollTitle }}</strong>
                                    </p>
                                    <p>&nbsp;</p>
                                </div>

                                <p v-if="voteAfterLogin" class="text-white text-3xl">Per completare il voto accedi!</p>

                                <p class="text-3xl text-white font-black text-center" style="margin-bottom: 10px;"
                                   v-else>
                                    Accedi! </p>

                                <div class="mx-4 flex flex-col items-center text-xs space-y-2 mt-4">

                                    <div class="text-error" v-if="$v.$error" style="padding-bottom: 20px;">
                                        <strong>E' necessario accettare entrambe le opzioni</strong>
                                    </div>

                                    <label class="flex items-start justify-center space-x-2 text-lg"
                                           :class="{'has-error': $v.loginForm.model.privacy_consent.$error}">
                                        <input
                                            type="checkbox"
                                            name="privacy_consent"
                                            value="1"
                                            class="mt-1 ring-2 bg-current-theme ring-white h-4 w-4 rounded"
                                            v-model="loginForm.model.privacy_consent"
                                            @change="$v.$dirty ? $v.loginForm.model.privacy_consent.$touch() : () => {}">
                                        <div>
                                            Accetto i
                                            <a href="https://www.rockol.it/info/privacy"
                                               target="_blank"
                                               class="underline"
                                               rel="nopener noreferrer">Termini di servizio</a>
                                        </div>
                                    </label>

                                    <label class="flex items-start justify-center space-x-2 text-lg"
                                           :class="{'has-error': $v.loginForm.model.awards_consent.$error}">
                                        <input
                                            type="checkbox"
                                            name="awards_consent"
                                            value="1"
                                            class="mt-1 ring-2 bg-current-theme ring-white h-4 w-4 rounded"
                                            v-model="loginForm.model.awards_consent"
                                            @change="$v.$dirty ? $v.loginForm.model.awards_consent.$touch() : () => {}">
                                        <div>Accetto di partecipare ai Rockol Awards e di esprimere il mio voto</div>
                                    </label>
                                </div>

                                <div class="mt-8">

                                    <ul v-if="!loginForm.showForm"
                                        class='mx-auto flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4 flex-wrap justify-center items-center'>
                                        <li>
                                            <a href=""
                                               class="rounded shadow-lg flex items-center justify-center space-x-2 px-4 py-3 border border-transparent text-base font-medium text-current-theme bg-white hover:bg-indigo-50 sm:px-8"
                                               :style="{ opacity: $v.$invalid ? '1' : '1'}"
                                               @click.prevent="login('facebook')">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve"
                                                         class="w-8 h-8" viewBox="0 0 40 40">
  <linearGradient id="a" x1="-277.375" x2="-277.375" y1="406.6018" y2="407.5726"
                  gradientTransform="matrix(40 0 0 -39.7778 11115.001 16212.334)" gradientUnits="userSpaceOnUse">
    <stop offset="0" stop-color="#0062e0"/>
      <stop offset="1" stop-color="#19afff"/>
  </linearGradient>
                                                        <path fill="url(#a)"
                                                              d="M16.7 39.8C7.2 38.1 0 29.9 0 20 0 9 9 0 20 0s20 9 20 20c0 9.9-7.2 18.1-16.7 19.8l-1.1-.9h-4.4l-1.1.9z"/>
                                                        <path fill="#fff"
                                                              d="m27.8 25.6.9-5.6h-5.3v-3.9c0-1.6.6-2.8 3-2.8H29V8.2c-1.4-.2-3-.4-4.4-.4-4.6 0-7.8 2.8-7.8 7.8V20h-5v5.6h5v14.1c1.1.2 2.2.3 3.3.3 1.1 0 2.2-.1 3.3-.3V25.6h4.4z"/>
</svg>
                                                </div>
                                                <div>Facebook</div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""
                                               class="rounded shadow-lg flex items-center justify-center space-x-2 px-4 py-3 border border-transparent text-base font-medium text-current-theme bg-white hover:bg-indigo-50 sm:px-8"
                                               :style="{ opacity: $v.$invalid ? '1' : '1'}"
                                               @click.prevent="login('google')">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 24 24" class="w-8 h-8">
                                                        <path
                                                            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                                            fill="#4285F4"/>
                                                        <path
                                                            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                                            fill="#34A853"/>
                                                        <path
                                                            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                                            fill="#FBBC05"/>
                                                        <path
                                                            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                                            fill="#EA4335"/>
                                                        <path d="M1 1h22v22H1z" fill="none"/>
                                                    </svg>
                                                </div>
                                                <div>Google</div>
                                            </a>
                                        </li>
                                        <li class="hidden sm:block text-sm text-current-theme">oppure</li>
                                        <li>
                                            <a href=""
                                               class="rounded shadow-lg flex items-center justify-center space-x-2 px-4 py-3 border border-transparent text-base font-medium text-current-theme bg-white hover:bg-indigo-50 sm:px-8"
                                               :style="{ opacity: $v.$invalid ? '1' : '1'}"
                                               @click.prevent="loginForm.showForm = true">
                                                <div>
                                                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.16 340.16" class="w-8 h-8"><path d="M170.07 340.16A170.08 170.08 0 1 0 0 170.08a170.08 170.08 0 0 0 170.07 170.08"/><path d="M188.06 349.36c44.61 13.18 76-18.59 84.62-38.79l15.75-35.38c14.5-32.57 34.79-57.37 72.17-57.37 10.36 0 18.75 1.11 25.7 6.3l28.83-64.77h-13c-26.27 0-53.09 10.36-76.42 33.68H325l13.83-31.09h-67.35M163.09 307.28a19.71 19.71 0 0 0-1.65 2.88c-1.76 3.95-.88 7.71 1.39 11.6v.1l-4.33-3.37-3.7 8.31 23.48 18.34 4.02-9.14-9.91-7.74c-4.6-3.59-6.29-8.12-4.39-12.38a10.8 10.8 0 0 1 1.64-2.88ZM188.76 305.94c-1.87 4.21-5.75 3.5-9.22.79s-5.85-6.89-4-11.1 5.75-3.5 9.26-.76 5.82 6.87 3.94 11.07m.26-20.49c-7.79-6.09-15.25-3-19.14 5.74s-2.33 18.88 5.47 25 15.21 2.94 19.09-5.78 2.33-18.88-5.42-24.93M189.27 251.62a15.4 15.4 0 0 0-4.54 6.23c-3.88 8.71-2.33 18.87 5.47 25s15.21 2.94 19.09-5.78a30.2 30.2 0 0 0 2.28-8.26l-6.13-4.51a16.1 16.1 0 0 1-1.4 6.55c-1.76 4-5.77 5.6-9.51 2.68a10.07 10.07 0 0 1-3.38-12.06 9.09 9.09 0 0 1 3.77-4.51Z" transform="translate(-114.87 -76.01)" style="fill:#fff"/><path style="fill:#fff" d="m68.68 166.43 29.99 23.42 4.06-9.12-11.63-9.08.05-.1 14.87 1.78 4.92-11.05-17.13.01-6.68-17.58-4.45 9.98L89.17 170l-.05.1-16.38-12.79-4.06 9.12z"/><path d="M228.23 217.3c-1.87 4.21-5.75 3.5-9.22.79s-5.86-6.9-4-11.1 5.75-3.5 9.26-.76 5.81 6.86 3.94 11.07m.26-20.49c-7.8-6.09-15.26-3-19.14 5.74s-2.33 18.88 5.46 25 15.21 2.93 19.1-5.78 2.33-18.88-5.42-24.93" transform="translate(-114.87 -76.01)" style="fill:#fff"/><path style="fill:#fff" d="m97.56 101.56 29.99 23.42 4.06-9.12-29.99-23.43-4.06 9.13z"/></svg>
                                                </div>
                                                <div>MyRockol / MusicBiz</div>

                                            </a>
                                        </li>
                                    </ul>

                                    <form v-if="loginForm.showForm" action=""
                                          @submit.prevent="loginWithUsernameAndPassword">

                                        <div class="flex flex-col items-center justify-center space-y-2 mb-2">
                                            <input placeholder="Indirizzo e-mail" type="text"
                                                   class="rounded text-current-theme"
                                                   v-model="loginForm.model.prompt.username">
                                            <input placeholder="Password" type="password"
                                                   class="rounded text-current-theme"
                                                   v-model="loginForm.model.prompt.password">
                                        </div>

                                        <div class="flex items-center justify-center space-x-2 mb-2">
                                            <button type="submit"
                                                    class="rounded shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-white bg-current-theme hover:bg-indigo-50 sm:px-8"
                                            >Login
                                            </button>
                                            <button type="button"
                                                    class="rounded opacity-70 shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                                                    @click.prevent="loginForm.showForm = false">
                                                Annulla
                                            </button>
                                        </div>
                                    </form>

                                </div>

                            </section>
                        </div>

                    </div>
                </div>
            </div>

            <div class="mt-8">
                <ad-unit ad-type="ad-lazy-load" :is-lazy-loaded="true" style="margin-bottom: 16px;"></ad-unit>
            </div>
        </div>
    </modal-view>
</template>

<script>
import {
    ARTIST_CANDIDATE_TYPE,
    CREATOR_CANDIDATE_TYPE,
    pollDefinition,
    RECORD_CANDIDATE_TYPE,
    VIDEO_CANDIDATE_TYPE,
} from '../../shared/common';
import SvgIcon from '../shared/SvgIcon';
import { mapGetters } from 'vuex';
import format from 'date-fns/format';
import ModalView from '../page-components/ModalView';
import AdUnit from '../shared/AdUnit';

export default {
    created: function () {
        const vm = this;
        window.addEventListener('keyup', function (e) {
            if (e.key === 'Escape') {
                vm.$store.commit('closeLoginPanel');
                vm.$store.commit('setVoteAfterLogin', null);
            }
        });
    },
    components: {
        AdUnit,
        SvgIcon,
        ModalView,
    },
    validations: {
        loginForm: {
            model: {
                privacy_consent: {
                    checked: (val) => {
                        return val === true;
                    },
                },
                awards_consent: {
                    checked: (val) => {
                        return val === true;
                    },
                },
            },
        },
    },
    watch: {
        showLoginPanel(show) {
            this.resetForm();
            if (show) {
                this.$root.$emit('show-login-panel');
            }
        },
    },
    data() {
        return {
            panelOpened: this.$store.state.loginPanel.show,
            loginForm: {
                isSubmitting: false,
                isSubmitted: false,
                showForm: false,
                model: {
                    prompt: {
                        username: '',
                        password: '',
                    },
                    privacy_consent: false,
                    awards_consent: false,
                },
            },
        };
    },
    computed: {
        ...mapGetters(['isVoteClosed', 'isVoteOpenForUsers', 'isVoteOpenForJury']),
        userStartDate() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.start), 'DD/MM/YYYY HH:mm');
        },
        userEndDate() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.end), 'DD/MM/YYYY HH:mm');
        },
        juryStartDate() {
            return format(new Date(this.$store.state.app.voteDatesForJury.start), 'DD/MM/YYYY HH:mm');
        },
        juryEndDate() {
            return format(new Date(this.$store.state.app.voteDatesForJury.end), 'DD/MM/YYYY HH:mm');
        },
        pollTitle() {
            if (this.voteAfterLogin) {
                return pollDefinition[this.voteAfterLogin.pollName].title;
            }
            return null;
        },
        candidateTitle() {
            const candidate = this.voteAfterLogin;

            switch (candidate.candidateType) {
                case ARTIST_CANDIDATE_TYPE:
                    return candidate.candidateData.artist_data.artist_name;
                case RECORD_CANDIDATE_TYPE:
                    return candidate.candidateData.artist_name + ' - ' + candidate.candidateData.release_title;
                case VIDEO_CANDIDATE_TYPE:
                    return candidate.candidateData.title + ' - ' + candidate.candidateData.artist_name;
                case CREATOR_CANDIDATE_TYPE:
                    return candidate.candidateData.creator_name;
            }

            return null;
        },
        candidateImage() {
            const candidate = this.voteAfterLogin;

            switch (candidate.candidateType) {
                case ARTIST_CANDIDATE_TYPE:
                    return candidate.candidateData.artist_data.artist_image;
                case RECORD_CANDIDATE_TYPE:
                    return candidate.candidateData.record_cover;
            }

            return null;
        },
        voteAfterLogin() {
            return this.$store.getters.voteAfterLogin;
        },
        showLoginPanel() {
            return this.$store.getters.loginPanel.show;
        },
    },
    methods: {
        resetForm() {
            this.$v.$reset();
            this.loginForm.model.privacy_consent = false;
            this.loginForm.model.awards_consent = false;
            this.loginForm.isSubmitted = false;
            this.loginForm.isSubmitting = false;
        },
        login(provider) {
            this.$v.loginForm.model.$touch();

            if (this.$v.$error) {
                this.loginForm.isSubmitted = false;
                this.loginForm.isSubmitting = false;
                return;
            }

            this.$store.dispatch('userSocialLogin', { provider });
        },
        async loginWithUsernameAndPassword() {
            this.$v.loginForm.model.$touch();

            if (this.$v.$error) {
                this.loginForm.isSubmitted = false;
                this.loginForm.isSubmitting = false;
                return;
            }

            try {
                await this.$store.dispatch('loginWithUsernameAndPassword', {
                    email: this.loginForm.model.prompt.username,
                    password: this.loginForm.model.prompt.password,
                });

                this.closePanel();
            } catch (error) {
                this.$store.commit('setError', error.publicMessage || 'Impossibile effettuare l\'autenticazione');
                this.$store.commit('loggingIn', false);
                this.loginForm.isSubmitted = false;
                this.loginForm.isSubmitting = false;
            }

        },
        closePanel() {
            this.$store.commit('closeLoginPanel');
            this.$store.commit('refreshAdv');
            this.$store.commit('setVoteAfterLogin', null);
        },
    },
};
</script>

