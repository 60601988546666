<template>
    <div class="candidate" :class="{ 'candidateNotChosen': (userAlreadyVoted && !isVotedCandidate)}">

        <div class="candidate-mobile-box visible-xs">
            <h2 class="candidate-name">{{ candidate.creator_name }}</h2>
        </div>

        <figure @click.prevent="candidateDetails">
            <div class="box">
                <div class="content">
                    <div v-if="candidate.photo_big">
                        <img class="img-responsive"
                             :src="candidate.photo_big"
                             :srcset="responsiveImageSrcSet"
                             :sizes="responsiveImageSizes"
                             :title="candidate.candidateCompleteName"
                             :alt="candidate.candidateCompleteName">
                    </div>
                </div>

            </div>

            <div class="hidden-xs content-overlay"></div>

            <div class="hidden-xs content-details">
                <figcaption class="album-play-button">
                    <h2 class="candidate-hover-title">{{ candidate.creator_name }}</h2>
                </figcaption>
            </div>
        </figure>

        <p v-if="userIsJuror"><br>Non votabile dalla giuria</p>
        <div class="candidate-controls" :class="{ centered: userAlreadyVoted || userIsJuror }">
            <vote-button v-if="userCanVote" :pollName="pollName" :itemId="itemId"></vote-button>
            <share-button :pollName="pollName" :itemId="itemId" :itemCid="itemCid"></share-button>
        </div>
    </div>
</template>

<script>
import has from 'lodash/has';
import VoteButton from '../buttons/VoteButton.vue';
import ShareButton from '../buttons/ShareButton.vue';
import {CREATOR_CANDIDATE_TYPE} from '../../../shared/common';

export default {
    props: ['candidate'],
    data() {
        return {}
    },
    components: {
        'vote-button': VoteButton,
        'share-button': ShareButton,
    },
    methods: {
        candidateDetails() {
            this.$store.dispatch('showCandidateDetails', {
                type: CREATOR_CANDIDATE_TYPE,
                id: this.candidate.uid
            })
        }
    },
    computed: {
        userIsJuror() {
            return (this.$store.getters.getUser) ? this.$store.getters.getUser.isJuror : false;
        },
        userCanVote() {
            return !this.userAlreadyVoted && !this.userIsJuror;
        },
        userAlreadyVoted() {
            return this.$store.getters.hasAlreadyVotedForThisPoll;
        },
        isVotedCandidate() {
            return !!(
                this.userAlreadyVoted &&
                has(this.$store.getters.userVoteState, 'votes[' + this.pollName + '].data.uid') &&
                this.$store.getters.userVoteState.votes[this.pollName].data.uid === this.itemId
            );
        },
        pollName() {
            return this.$store.getters.currentPollCode
        },
        itemId() {
            return this.candidate.uid;
        },
        itemCid() {
            return this.candidate.cid;
        },
        candidateCompleteName() {
            return this.candidate.artist_name + " " + this.candidate.title;
        },
        responsiveImageSrcSet() {
            const srcset = [];
            if (this.candidate.photo_big) {
                srcset.push(`${this.candidate.photo_big} 450w`)
            }
            if (this.candidate.photo_medium) {
                srcset.push(`${this.candidate.photo_medium} 320w`)
            }
            if (this.candidate.photo_small) {
                srcset.push(`${this.candidate.photo_small} 150w`)
            }
            return srcset.join(",");
        },
        responsiveImageSizes() {
            const sizes = [];
            sizes.push('(max-width: 320px) 150px');
            sizes.push('(max-width: 488px) 268px');
            sizes.push('(max-width: 767px) 442px');
            sizes.push('(max-width: 990px) 304px');
            sizes.push('(max-width: 1200px) 179px');
            sizes.push('(max-width: 1600px) 250px');
            sizes.push('450px');
            return sizes.join(",");
        }
    }
}
</script>

<style lang="scss">
</style>
