import {FIREBASE_ERRORS} from "../../shared/firebaseErrors.it";
import sendErrorToSentry from "../../shared/errorReporting";

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

export const resetPassword = {
    namespaced: true,
    state: {
        submitted: false,
        callbackUrl: null,
        successMessage: '',
        isSubmitting: false,
        authErrors: [],
        model: {
            username: '',
        }
    },
    mutations: {
        setUsername(state, username) {
            state.model.username = username;
        },
        setCallbackUrl(state, url) {
            state.callbackUrl = url;
        },
        addAuthError(state, errorMessage) {
            state.authErrors.push(errorMessage)
        },
        resetAuthErrors(state) {
            state.authErrors = [];
        },
        resetForm(state) {
            state.model.username = '';
            state.submitted = false;
            state.isSubmitting = false;
            state.authErrors = [];
        },
        prepareSubmit(state) {
            state.submitted = true;
            state.isSubmitting = true;
            state.successMessage = '';
            state.authErrors = [];
        },
        submitDone(state) {
            state.isSubmitting = false;
        },
        successMessage(state, message) {
            state.successMessage = message;
        }
    },
    actions: {
        resetFirebasePassword({commit, state}, emailAddress) {
            const resetSuccess = () => {
                commit('successMessage', "Ti è stata inviata una e-mail con le istruzioni per procedere al reset della password.");
                commit('submitDone');
            };
            const errorCb = (error) => {
                const errMessage = FIREBASE_ERRORS[error.code] || "Errore generico";
                commit('addAuthError', errMessage);
                commit('submitDone');
            };
            try {
                const actionCodeSettings = {
                    url: state.callbackUrl,
                };
                firebase
                    .auth()
                    .sendPasswordResetEmail(emailAddress, actionCodeSettings)
                    .then(() => {
                        resetSuccess();
                    })
                    .catch((error) => {
                        errorCb(error);
                    })

            } catch (error) {
                sendErrorToSentry(error);
                errorCb(error);
            }
        },
        reset({commit, state, dispatch, getters}) {
            const resetPassword = state.resetPassword;
            commit('prepareSubmit');
            commit('resetAuthErrors');

            if (getters.formErrors.length === 0) {
                dispatch(
                    'resetFirebasePassword',
                    state.model.username
                )
            } else {
                commit('submitDone');
            }
        }
    },
    getters: {
        form(state) {
            return state;
        },
        formErrors(state) {
            let errors = [];

            state.authErrors.forEach((err) => errors.push(err));

            if (state.model.username.length === 0) {
                errors.push("L'indirizzo e-mail non può essere vuoto");
            }

            return errors;
        }
    }
};