<template>
    <div class="max-w-md mx-auto pb-4">

        <section v-if="!isVoteOpenForJury">
            <h2>Le votazioni per la giuria sono chiuse</h2>
            <p>In caso di problemi contattaci: platform@rockol.it</p>
        </section>
        <section v-else>
            <!-- Controllo del link -->
            <section v-if="link.checking">
                <h1>Controllo della richiesta di accesso in corso</h1>
                <i class="fa fa-circle-o-notch fa-spin fa-4x"></i>
            </section>

            <!-- Termine controllo -->
            <section v-else>

                <!-- Link non valido -->
                <div v-if="!link.valid">
                    <h1>Codice di richiesa di accesso non valido</h1>
                    <p>
                        <strong class="text-danger">
                            La tua richiesta di richiesta di accesso non è valida,
                            è scaduta oppure è già stata completata.
                        </strong>
                    </p>
                    In caso di problemi contattaci: platform@rockol.it
                </div>

                <!-- Link valido -->
                <div v-else>

                    <!-- Screen di accesso in corso -->
                    <section v-if="!form.show">
                        <section v-if="form.isSubmitting">
                            <h2>Accesso in corso</h2>
                            <svg-icon icon="loading" :width="150" :height="150" cssClass="white-icon"></svg-icon>
                        </section>

                        <div v-if="form.error" style="padding-bottom: 30px;">
                            <p>
                                <strong :class="{'text-danger': form.error, 'text-success': !form.error}"
                                        v-html="form.message">
                                </strong>
                            </p>

                            In caso di problemi contattaci: awards@rockol.it

                            <p>&nbsp;</p>

                            <router-link v-if="isVoteOpenForJury"
                                         :to="{name: 'juryLogin'}"
                                         class="btn btn-secondary">Riprova
                            </router-link>
                        </div>

                    </section>

                    <!-- Form di inserimento dell'indirizzo email se non presente -->

                    <section v-if="form.show">
                        <div v-if="!form.isSubmitted">

                            <h1>Accedi come giurato</h1>

                            <!-- Error -->
                            <div v-if="form.error" style="padding-bottom: 30px;">
                                <p>
                                    <strong :class="{'text-danger': form.error, 'text-success': !form.error}"
                                            v-html="form.message">
                                    </strong>
                                </p>

                                In caso di problemi contattaci: awards@rockol.it

                                <p>&nbsp;</p>

                                <router-link :to="{name: 'juryLogin'}"
                                             class="btn btn-secondary">Riprova
                                </router-link>
                            </div>


                            <form action=""
                                  @submit.prevent="login()"
                                  v-if="!form.isSubmitting || !form.isSubmitted">
                                <div>
                                    <label for="newPassword" class="form-label">
                                        Inserisci il tuo indirizzo e-mail
                                    </label>
                                    <div class="form-group" style="position: relative;">

                                        <input id="email"
                                               name="email"
                                               class="form-control"
                                               type="email"
                                               :disabled="form.isSubmitting"
                                               v-model="form.email">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <svg-icon
                                            icon="loading"
                                            :width="50"
                                            :height="50"
                                            cssClass="white-icon"
                                            v-if="form.isSubmitting"
                                    ></svg-icon>

                                    <button type="submit"
                                            :disabled="form.isSubmitting"
                                            class="btn ">
                                        Accedi
                                    </button>
                                </div>
                            </form>

                        </div>
                    </section>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    import SvgIcon from "../../shared/SvgIcon";
    import {mapGetters} from "vuex";
    import sendErrorToSentry from "../../../shared/errorReporting";

    export default {
        components: {SvgIcon},
        props: [
            'mode',
            'oobCode',
            'apiKey',
            'continueUrl',
            'lang',
            'u'
        ],
        data() {
            return {
                link: {
                    checking: true,
                    valid: false,
                },
                form: {
                    show: true,
                    isSubmitting: false,
                    isSubmitted: false,
                    error: false,
                    message: '',
                    email: '',
                },
            }
        },
        computed: {
            ...mapGetters(['isVoteOpenForJury']),
            userLoggedInAndValid() {
                return this.$store.getters.userLoggedInAndValid;
            }
        },
        async mounted() {
            await this.$store.dispatch('userSignOut');

            this.link.valid = firebase.auth().isSignInWithEmailLink(window.location.href);
            this.link.checking = false;

            if (window.localStorage && window.localStorage.getItem('emailForSignIn')) {
                this.form.email = window.localStorage.getItem('emailForSignIn');
                this.form.show = false;
                this.login();
            }
        },
        methods: {
            async login() {
                if (this.form.email) {
                    this.form.error = false;
                    this.form.message = '';
                    try {
                        this.form.isSubmitting = true;

                        await this.$store.dispatch('loginJurorThroughEmail', {
                            email: this.form.email,
                            href: window.location.href
                        });

                        this.form.isSubmitted = true;

                        if (window.localStorage) {
                            window.localStorage.removeItem('emailForSignIn');
                        }

                        // Redirect to homepage
                        this.$router.push("/");
                    } catch (error) {
                        sendErrorToSentry(error);
                        console.error(error);
                        this.form.error = true;
                        this.form.isSubmitted = false;
                        this.form.message = error.publicMessage || 'Errore durante la fase di autenticazione, il link potrebbe non essere più valido.'
                    } finally {
                        this.form.isSubmitting = false;
                    }
                }
            },
        }
    }
</script>