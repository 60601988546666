import axios from 'axios'
import qs from 'qs'
import {juryApiSettings} from '../shared/settings'
import {userTokenIsValid} from "./common";


const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');



export const getJurorById = (uid) => {
    return makeApiCall(
        "getJurorData",
        {u: uid}
    );
};

export const getJurorByEmail = (email) => {
    return makeApiCall(
        "getJurorDataByEmail",
        {email}
    );
};

export const getJurorByIdAndSecret = (uid, secret) => {
    return makeApiCall(
        "getJurorData",
        {u: uid, secret: secret}
    );
};

export const checkJurorPin = (uid, secret, pin) => {
    return makeApiCall(
        "pinCheck",
        {
            u: uid,
            secret: secret,
        }
    );
};

export const activateJurorFromExistingUser = (payload) => {
    return makeApiCall(
        'activateJurorFromExistingUser',
        payload
    );
};

export const finalizeJurorActivation = (jurorObject) => {
    return makeApiCall(
        "finalizeJurorActivation",
        jurorObject
    );
    userTokenIsValid()
        .then((valid) => {
            if (!valid) {
                throw "Chiamata non valida";
            }
            return makeApiCall(
                "finalizeJurorActivation",
                jurorObject
            )
        })
        .then((res) => {
            return res && res.data && res.data.success;
        })
        .catch((err) => {
            return err;
        });
};

export const checkIfUserIsRegisteredWithPasswordProvider = (email) => {
    return firebase.auth()
        .fetchProvidersForEmail(email)
        .then((res) => {
            return (res.filter(x => x === "password").length > 0)
        });
};

export const checkIfUserHasMadeAccessWithPasswordProvider = (userData) => {
    if (userData && userData.providerData) {
        return userData.providerData.filter(x => x.providerId === 'password').length > 0;
    }
    return false;
};

const makeApiCall = (requestType, data, method) => {
    const separator = juryApiSettings.basePath.indexOf('?') !== -1 ? "&" : "?";
    const url = juryApiSettings.host + juryApiSettings.basePath + separator + "output=json&requestType=" + requestType;
    method = method || 'post';
    return axios({
        method: method,
        url: url,
        headers: juryApiSettings.defaultHeaders,
        data: qs.stringify(data)
    })
};
