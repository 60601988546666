<template>
    <div>
        <rockol-awards-header></rockol-awards-header>
        <rockol-awards-footer></rockol-awards-footer>

        <div class="fixed inset-0 z-0
            bg-gradient-to-br from-poll-gradient-from via-poll-gradient-via to-poll-gradient-to
        "
        ></div>

        <div class="app-main-view app-poll relative" :class="$store.getters.withFooter">

            <ad-unit ad-type="ad-masthead" :ad-unit="0" style="margin-bottom: 16px;"></ad-unit>

            <div class="mx-auto flex flex-col items-center text-white  space-y-8">

                <h1 class="text-5xl font-black text-center">{{ pollTitle }}</h1>

                <strong v-if="isLoading">
                    <svg-icon icon="loading"
                              :width="150"
                              :height="150"
                              cssClass="fill-current"
                              style="opacity: .5"></svg-icon>
                </strong>

                <div v-if="!isLoading" class="px-4 md:px-0 flex flex-col items-center space-y-8 w-full max-w-5xl">

                    <div class="flex  items-center space-x-8  opacity-50 hover:opacity-100 transition duration-300"
                         :class="(inputFocus) ? 'focus' : ''"
                         v-if="showPollControls">

                        <div class="flex items-center text-sm text-white">
                            <a href="" v-if="order==='shuffle'"
                               class="w-12 h-12 sm:w-4 sm:h-4 "
                               @click.prevent="order = 'alphabetical'">
                                <svg v-if="order==='shuffle'" xmlns="http://www.w3.org/2000/svg"
                                     class="fill-current w-full h-full"
                                     width="12"
                                     height="15"
                                     viewBox="0 0 24 24">
                                    <path
                                        d="M14.94 4.66h-4.72l2.36-2.36zm-4.69 14.71h4.66l-2.33 2.33zM6.1 6.27L1.6 17.73h1.84l.92-2.45h5.11l.92 2.45h1.84L7.74 6.27H6.1zm-1.13 7.37l1.94-5.18 1.94 5.18H4.97zm10.76 2.5h6.12v1.59h-8.53v-1.29l5.92-8.56h-5.88v-1.6h8.3v1.26l-5.93 8.6z"/>
                                </svg>
                            </a>

                            <a href="" v-if="order==='alphabetical'"
                               class="w-12 h-12 sm:w-4 sm:h-4 "
                               @click.prevent="order = 'shuffle'">
                                <svg v-if="order==='alphabetical'" xmlns="http://www.w3.org/2000/svg"
                                     class="fill-current w-full h-full"
                                     width="24"
                                     height="15" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                     id="Capa_1" x="0px" y="0px" viewBox="0 0 375.633 375.633" xml:space="preserve"
                                >
                                        <path
                                            d="M375.627,279.726l-78.877,67.608v-45.079h-13.277c-41.919,0-72.786-18.781-98.268-43.648   c9.828-11.569,18.738-23.214,27.027-34.108c1.904-2.513,3.796-4.993,5.684-7.473c18.852,19.494,39.129,32.645,65.562,32.645h13.277   v-37.568L375.627,279.726z M0,129.466h39.308c24.927,0,44.377,11.716,62.321,29.371c2.953-3.791,5.939-7.74,8.953-11.683   c7.337-9.66,15.093-19.831,23.497-29.975c-24.813-23.187-54.75-40.309-94.77-40.309H0V129.466z M296.75,28.299v44.818h-13.277   c-69.375,0-108.488,51.421-143.004,96.804c-31.046,40.749-57.85,75.989-101.161,75.989H0v52.59h39.308   c69.386,0,108.498-51.394,143.015-96.766c31.035-40.798,57.844-76.033,101.15-76.033h13.277v37.84l78.883-67.629L296.75,28.299z"></path>
                                    </svg>
                            </a>
                            &nbsp;
                            <a href="" v-if="order==='shuffle'"
                               class="hidden sm:block"
                               @click.prevent="order = 'alphabetical'"
                            > Passa all'ordine alfabetico </a>

                            <a href="" v-if="order==='alphabetical'"
                               class="hidden sm:block"
                               @click.prevent="order = 'shuffle'"
                            >Passa all'ordine casuale</a>

                        </div>

                        <div class="text-3xl hidden sm:block">
                            <strong>{{ candidates.length }}</strong> candidati
                        </div>


                        <div class="relative  ">
                            <input type="text"
                                   placeholder="Cerca"
                                   v-model.lazy="searchCandidate"
                                   v-debounce="500"
                                   class="border-0 bg-transparent border-b-2 border-white text-white focus:ring-0 focus:ring-white focus:border-white focus:border-b-4 placeholder-white placeholder-opacity-40"
                                   @focus="inputFocus = true"
                                   @blur="inputFocus = false">
                            <svg class="absolute right-0 top-4 fill-current" width="15" height="15" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"
                                 xml:space="preserve">
                                        <g><path d="M681.1,681.1c153.5-153.5,153.5-402.4,0-556c-153.5-153.5-402.4-153.5-556,0c-153.5,153.5-153.5,402.4,0,556C278.7,834.7,527.6,834.7,681.1,681.1L681.1,681.1L681.1,681.1L681.1,681.1z M619.3,619.3c-119.4,119.4-313,119.4-432.4,0c-119.4-119.4-119.4-313,0-432.4c119.4-119.4,313-119.4,432.4,0C738.8,306.3,738.8,499.9,619.3,619.3L619.3,619.3L619.3,619.3L619.3,619.3z"/>
                                            <path d="M619.3,681.1l61.8-61.8L990,928.2L928.2,990L619.3,681.1z"/></g>
                                    </svg>
                        </div>
                    </div>

                    <!-- Voted candidate -->
                    <div v-if="votedCandidate" class="space-y-4">
                        <h3 class="text-center text-2xl">
                            Hai votato per
                            <span v-html="candidateClaim(votedCandidate)"></span>
                        </h3>
                        <div>
                            <record-candidate
                                v-if="candidateType === 'record'"
                                :candidate="votedCandidate"/>
                            <artist-candidate
                                v-if="candidateType === 'artist'"
                                :candidate="votedCandidate"/>
                            <video-candidate
                                v-if="candidateType === 'video'"
                                :candidate="votedCandidate"/>
                            <creator-candidate
                                v-if="candidateType === 'creator'"
                                :candidate="votedCandidate"/>
                        </div>
                        <hr>
                    </div>

                    <!-- Highlighted candidate -->
                    <div v-if="highlightedCandidate" class="space-y-4">
                        <h3 class="text-center text-2xl">
                            Vota per
                            <span v-html="candidateClaim(highlightedCandidate)"></span>
                        </h3>
                        <div>
                            <record-candidate
                                v-if="candidateType === 'record'"
                                :candidate="highlightedCandidate"/>
                            <artist-candidate
                                v-if="candidateType === 'artist'"
                                :candidate="highlightedCandidate"/>
                            <video-candidate
                                v-if="candidateType === 'video'"
                                :candidate="highlightedCandidate"/>
                            <creator-candidate
                                v-if="candidateType === 'creator'"
                                :candidate="highlightedCandidate"/>
                        </div>
                        <hr/>
                    </div>

                    <!-- Candidate list -->
                    <div class="mx-auto w-full max-w-5xl" v-if="!isLoading">

                        <div class="flex flex-wrap justify-center text-3xl font-semibold py-4" v-if="candidateList.length === 0">
                            Nessun candidato trovato
                        </div>

                        <ul class="flex flex-wrap justify-center" v-if="candidateList.length > 0">
                            <li class="w-full sm:w-1/2 md:w-1/3 items-center justify-center p-4"
                                v-for="candidate in candidateList" :key="candidate.id">
                                <ad-unit v-if="candidate.is_adv"
                                         ad-type="ad-rectangle"
                                         :ad-unit="candidate.ad_unit || null"></ad-unit>
                                <record-candidate
                                    v-if="!candidate.is_adv && candidateType === 'record'"
                                    :candidate="candidate"/>
                                <artist-candidate
                                    v-if="!candidate.is_adv && candidateType === 'artist'"
                                    :candidate="candidate"/>
                                <video-candidate
                                    v-if="!candidate.is_adv && candidateType === 'video'"
                                    :candidate="candidate"/>
                                <creator-candidate
                                    v-if="!candidate.is_adv && candidateType === 'creator'"
                                    :candidate="candidate"/>
                            </li>
                        </ul>
                    </div>

                    <hr class="my-8">

                    <poll-disclaimer></poll-disclaimer>
                </div>

            </div>

            <iubenda-consent-links></iubenda-consent-links>
        </div>

    </div>
</template>

<script>
import {
    ARTIST_CANDIDATE_TYPE,
    BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME,
    BEST_ITALIAN_RECORDS_POLL_NAME,
    CREATOR_CANDIDATE_TYPE,
    getCandidatesPromise,
    pollDefinition,
    RECORD_CANDIDATE_TYPE,
    scrollToTop,
    VIDEO_CANDIDATE_TYPE
} from "../../shared/common";
import shuffle from 'lodash/shuffle';
import lodashDebounce from 'lodash/debounce';
import get from 'lodash/get';
import debounce from 'v-debounce'
import RecordCandidate from '../candidates/records/RecordCandidate.vue';
import ArtistCandidate from '../candidates/artists/ArtistCandidate.vue';
import VideoCandidate from '../candidates/videos/VideoCandidate.vue';
import CreatorCandidate from '../candidates/creators/CreatorCandidate.vue';
import Header from '../page-components/Header.vue';
import Footer from '../page-components/Footer.vue';
import PollDisclaimer from '../page-components/PollDisclaimer.vue';
import SvgIcon from "../shared/SvgIcon";
import AdUnit from "../shared/AdUnit";
import IubendaConsentLinks from "../shared/IubendaConsentLinks";

const getCandidates = async (pollName, chosenCandidateCid, chosenCandidateSlug, vueInstance) => {
    vueInstance.candidates = [];
    vueInstance.candidateFetchingInProgess = true;
    const delay = time => new Promise(res => setTimeout(() => res(), time));

    const promises = [
        getCandidatesPromise(pollName),
        delay(500)
    ];

    const results = await Promise.all(promises);

    const response = results[0];

    let candidatesFromApi = response.data ? response.data : [];
    vueInstance.candidates = candidatesFromApi.map((candidate) => {
        candidate.hilighted = chosenCandidateCid !== null && candidate.cid === chosenCandidateCid;
        candidate.hilighted = chosenCandidateSlug !== null && candidate.slug === chosenCandidateSlug;
        return candidate;
    });

    vueInstance.candidateFetchingInProgess = false;
};

function setCurrentPollFromRoute() {
    this.$store.dispatch('closeAllModals');
    const poll = pollDefinition[this.pollName];
    this.$store.commit('setCurrentPoll', {
        code: null,
        title: null,
    });
    if (poll) {
        this.$store.commit('setCurrentPoll', {
            code: this.pollName,
            title: poll.title,
        });
    }
    this.order = 'shuffle';
    this.searchCandidate = '';
    scrollToTop();
}

function insertAdvInCandidatesList(candidatesToShow, startingFrom, every) {
    const adUnitsIds = [
        7, 8, 1, 2, 3, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5
    ];

    while (startingFrom <= candidatesToShow.length) {
        const unitToPut = adUnitsIds.shift();

        candidatesToShow.splice(startingFrom, 0, {
            is_adv: true,
            ad_unit: unitToPut,
        });

        startingFrom = (startingFrom + every + 1);
    }
}

export default {
    props: ['pollName', 'candidateType'],
    directives: {
        debounce
    },
    components: {
        IubendaConsentLinks,
        SvgIcon,
        AdUnit,
        recordCandidate: RecordCandidate,
        artistCandidate: ArtistCandidate,
        videoCandidate: VideoCandidate,
        creatorCandidate: CreatorCandidate,
        rockolAwardsHeader: Header,
        rockolAwardsFooter: Footer,
        pollDisclaimer: PollDisclaimer,
    },
    watch: {
        isLoading(loading) {
            if (!this.initialized && !loading) {
                this.$root.$emit('poll-loaded');
                this.$root.$emit('start-adv');
            }
            this.initialized = true;
        }
    },
    data() {
        return {
            initialized: false,
            candidateFetchingInProgess: true,
            currentBodyClass: '',
            candidates: [],
            order: 'shuffle',
            inputFocus: false,
            searchString: '',
            searchCandidate: '',
        }
    },
    methods: {
        updateSearch() {
            const vm = this;
            lodashDebounce(
                function () {
                    vm.searchString = vm.searchCandidate;
                },
                2000
            );
        },
        candidateClaim(candidate) {
            if (candidate) {
                switch (candidate.type) {
                    case ARTIST_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.artist_name}'</strong>
                            `;
                    case RECORD_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.record_title}'</strong> di <strong>${candidate.artist_name}</strong>
                            `;
                    case VIDEO_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.title}'</strong> - <strong>'${candidate.artist_name}'</strong>
                            `;
                    case CREATOR_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.creator_name}'</strong>
                            `;
                }
            }
            return null;

        }
    },
    computed: {
        isBestItalianAlbum() {
            return this.pollName === BEST_ITALIAN_RECORDS_POLL_NAME;
        },
        isBestItalianLive() {
            return this.pollName === BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME;
        },
        isLoading() {
            return this.candidateFetchingInProgess || this.loginCheckInProgress
        },
        loginCheckInProgress() {
            return this.$store.getters.loginCheckInProgress
        },
        orderCandidates() {
            if (this.order === 'shuffle') {
                return shuffle(this.candidates);
            }
            if (this.order === 'alphabetical') {
                return this.candidates.concat().sort(function (a, b) {
                    var keyA = a.ordered_candidate,
                        keyB = b.ordered_candidate;
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }

        },
        candidateList() {
            let candidatesToShow = [];

            candidatesToShow = this.orderCandidates;

            if (this.userAlreadyVoted) {
                return candidatesToShow.filter(x => x.uid !== this.votedCandidateUid);
            }

            if (this.searchCandidate) {
                return candidatesToShow.filter((x) => {
                    const search = this.searchCandidate.toLowerCase();
                    const candidate = x.search_string.toLowerCase();
                    return candidate.indexOf(search) !== -1
                });
            }

            candidatesToShow = candidatesToShow.filter(x => x.hilighted === false);

            // Inseriamo gli spazi adv
            let startingFrom = 1;
            let every = 4;
            insertAdvInCandidatesList(candidatesToShow, startingFrom, every);

            return candidatesToShow;
        },
        highlightedCandidate() {
            if (this.userAlreadyVoted) {
                return null;
            }
            return this.candidates.filter(x => x.hilighted === true)[0];
        },
        showPollControls() {
            return !this.votedCandidate && !this.highlightedCandidate;
        },
        votedCandidate() {
            if (this.userAlreadyVoted) {
                return this.candidates.filter(x => x.uid === this.votedCandidateUid)[0];
            }
            return null;
        },
        votedCandidateUid() {
            return get(this.$store.getters.userVoteState, 'votes[' + this.pollName + '].data.uid', null);
        },
        userAlreadyVoted() {
            return this.$store.getters.hasAlreadyVotedForThisPoll;
        },
        pollTitle() {
            if (pollDefinition && pollDefinition[this.pollName]) {
                return pollDefinition[this.pollName].title;
            }
            return null;
        },
        chosenCandidateCid() {
            return (this.$route.query && this.$route.query.cid)
                ? this.$route.query.cid
                : null;
        },
        chosenCandidateSlug() {
            return (this.$route.params.slug)
                ? this.$route.params.slug
                : null;
        }

    },
    async created() {
        await getCandidates(this.pollName, this.chosenCandidateCid, this.chosenCandidateSlug, this);
        setCurrentPollFromRoute.call(this);
    }
}
</script>
