<!--suppress SpellCheckingInspection -->
<template>
    <renderless-login-button>
        <div slot-scope="{
                loginCheckInProgress,
                userInitials,
                userLoggedIn,
                user,
                displayName,
                showLogin,
                openUserPanel,
            }">
            <div v-if="loginCheckInProgress">
                <svg-icon icon="loading"
                          :width="30"
                          :height="30"
                          cssClass="fill-current"
                          style="opacity: .5"></svg-icon>
            </div>
            <div v-if="!loginCheckInProgress">
                <slot name="voteButton" :userLoggedIn="userLoggedIn" :showLogin="showLogin">
                    <button @click.prevent="showLogin" v-if="!userLoggedIn">
                        <div
                            class="px-6 py-2 border border-transparent text-base font-bold rounded-md text-white tracking-wide bg-current-theme opacity-90 hover:opacity-100 md:text-lg"
                        >Vota!
                        </div>
                    </button>
                </slot>

                <div v-if="userLoggedIn"
                     class="text-black w-12 h-12 rounded-full border-4 border-current-theme">
                    <a href="#"
                       class="flex items-center justify-center text-black w-full h-full rounded-full border-4 text-white"
                       @click.prevent="openUserPanel"
                    >
                        {{ userInitials }}
                    </a>
                </div>
            </div>
        </div>
    </renderless-login-button>

</template>


<script>
import RenderlessLoginButton from "./RenderlessLoginButton";
import SvgIcon from "../shared/SvgIcon";

export default {
    name: 'DefaultLoginButton',

    components: {
        SvgIcon,
        RenderlessLoginButton
    },

    computed: {},

    data() {
        return {}
    },

    methods: {}
}
</script>

