<template>
    <div>
        <rockol-awards-header></rockol-awards-header>
        <rockol-awards-footer></rockol-awards-footer>

        <div class="app-main-view" :class="$store.getters.withFooter">
            <div class="main_content">
                <jury-login-screen/>
            </div>
        </div>

    </div>
</template>

<style lang="scss">
    body.juryLogin {
        background: rgba(0,0,0,.8);
        color: white;
        label {
            color: white;
        }
    }
</style>
<script>
    import RockolAwardsHeader from '../page-components/Header.vue';
    import RockolAwardsFooter from '../page-components/Footer.vue';
    import JuryLoginScreen from '../user/JuryLoginScreen.vue';

    export default {
        components: {
            RockolAwardsHeader,
            RockolAwardsFooter,
            JuryLoginScreen
        },
        data() {
            return {}
        },
        created() {
            this.$store.dispatch('closeAllModals');
        }
    }
</script>
