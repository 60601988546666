export const FIREBASE_ERRORS = {
    "auth/user-not-found": "Utente non esistente",
    "auth/invalid-email": "Indirizzo e-mail non valido",
    "auth/email-already-in-use": "Indirizzo e-mail già in uso",
    "auth/operation-not-allowed": "Operazione non consentita",
    "auth/user-disabled": "L'utente è disabilitato",
    "auth/expired-action-code": "Il codice di verifica è scaduto",
    "auth/invalid-action-code": "Il codice di verifica non è valido",
    "auth/weak-password": "La password è troppo debole (minimo 6 caratteri)",
    "auth/wrong-password": "La password è errata",
    "auth/argument-error": "Il dato inserito non è in un formato valido",
    "auth/cancelled-popup-request": "L'operazione di autenticaione è stata cancellata perchè c'era un altro popup aperto."
};