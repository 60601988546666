import * as Sentry from '@sentry/browser';

let sendErrorToSentry = (error) => {
    console.error(error)
};

if (process.env.VUE_APP_SENTRY_DNS && Sentry) {
    sendErrorToSentry = (error) => {
        Sentry.captureException(error);
    };
}

export default sendErrorToSentry;