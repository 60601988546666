<template>
    <div class="relative text-white text-sm sm:text-2xs py-8 sm:py-0  flex flex-col space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row flex-wrap items-center justify-center">
        <a href="#" class="iubenda-cs-preferences-link underline opacity-70 hover:opacity-100 transition-all duration-150">Preferenze di consenso</a>
        <a href="#" class="iubenda-advertising-preferences-link underline opacity-70 hover:opacity-100 transition-all duration-150">Preferenze tracciamento pubblicitario</a>

        <router-link
            v-if="useVueRouter"
            :to="{ name: 'disclaimer' }" class="underline opacity-70 hover:opacity-100 transition-all duration-150">
            Disclaimer
        </router-link>
        <a :href="makeRoute('/disclaimer')" v-else class="underline opacity-70 hover:opacity-100 transition-all duration-150"
        > Disclaimer </a>

        <a href="https://www.rockol.it" target="_blank" rel="noopener noreferrer" class="underline opacity-70 hover:opacity-100 transition-all duration-150">Rockol.it</a>
    </div>
</template>

<script>
export default {
    name: "IubendaConsentLinks",
    data() {
        return {
            relativePath: process.env.VUE_APP_RELATIVE_PATH || '/',
            useVueRouter: (process.env.VUE_APP_USE_VUE_ROUTER || 'yes') === 'yes',
        }
    },
    methods: {
        makeRoute(route = '') {
            return (this.relativePath + route).replace(/\/\//g, "/");
        },
    }
}
</script>
