<template>
    <div>
        <rockol-awards-header></rockol-awards-header>
        <rockol-awards-footer></rockol-awards-footer>
        <div class="fixed inset-0">
            <div class="absolute inset-0 bg-current-theme">
                <img
                    src="./../../assets/img/bg-2021.png"
                    class="mix-blend-luminosity w-full h-full object-cover">
            </div>
            <div class="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div class="app-main-view app-home relative" :class="$store.getters.withFooter">

            <div class="">
                <!-- Hero card -->
                <div class="relative mt-4">
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div class="relative sm:overflow-hidden">
                            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 sm:pb-16 lg:pb-16 lg:px-8">
                                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                    <span class="block text-white">Ops! Pagina non trovata</span>
                                    <span class="block text-indigo-200">Non abbiamo trovato nessuna risorsa per l'url richiesto.</span>
                                </h1>

                                <div class="mt-10 max-w-sm mx-auto sm:max-w-none flex justify-center">
                                    <div class="">
                                        <renderless-login-button>
                                            <div slot-scope="{
                                                    loginCheckInProgress,
                                                    userInitials,
                                                    userLoggedIn,
                                                    user,
                                                    displayName,
                                                    showLogin,
                                                    openUserPanel,
                                                }">
                                                <button v-if="!userLoggedIn && !loginCheckInProgress"
                                                        @click="showLogin"
                                                        class="shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8">
                                                    Accedi!
                                                </button>
                                            </div>
                                        </renderless-login-button>
                                    </div>
                                </div>
                            </div>

                            <ad-unit ad-type="ad-masthead" :ad-unit="0" style="margin-bottom: 16px;"></ad-unit>

                            <iubenda-consent-links> </iubenda-consent-links>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss">
.form-panel {
    color: #DDD;
    max-width: 450px;
    margin: auto;

    label {
        font-size: 24px;

        &.checkbox {
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
        }
    }

    h2 {
        margin-bottom: 1em;
    }
}

.emailAddress {
    color: #dd3a60;
    font-size: 20px;
}

input {
    text-align: center;
}
</style>

<script>
import shuffle from 'lodash/shuffle';
import lodashDebounce from 'lodash/debounce';
import get from 'lodash/get';
import {
    ARTIST_CANDIDATE_TYPE,
    CREATOR_CANDIDATE_TYPE,
    getCandidatesPromise,
    pollDefinition,
    RECORD_CANDIDATE_TYPE,
    scrollToTop,
    VIDEO_CANDIDATE_TYPE
} from '../../shared/common';
import debounce from 'v-debounce'
import RecordCandidate from '../candidates/records/RecordCandidate.vue';
import ArtistCandidate from '../candidates/artists/ArtistCandidate.vue';
import VideoCandidate from '../candidates/videos/VideoCandidate.vue';
import CreatorCandidate from '../candidates/creators/CreatorCandidate.vue';
import Header from '../page-components/Header.vue';
import Footer from '../page-components/Footer.vue';
import PollDisclaimer from '../page-components/PollDisclaimer.vue';
import SvgIcon from "../shared/SvgIcon";
import IubendaConsentLinks from "../shared/IubendaConsentLinks";
import AdUnit from "../shared/AdUnit";

const getCandidates = async (pollName, chosenCandidateCid, vueInstance) => {
    vueInstance.candidates = [];
    vueInstance.candidateFetchingInProgess = true;
    const delay = time => new Promise(res => setTimeout(() => res(), time));

    const promises = [
        getCandidatesPromise(pollName),
        delay(500)
    ];

    const results = await Promise.all(promises);

    const response = results[0];

    let candidatesFromApi = response.data ? response.data : [];
    vueInstance.candidates = candidatesFromApi.map((candidate) => {
        candidate.hilighted = chosenCandidateCid !== null && candidate.cid === chosenCandidateCid;
        return candidate;
    });

    vueInstance.candidateFetchingInProgess = false;
};

function setCurrentPollFromRoute() {
    this.$store.dispatch('closeAllModals');
    const poll = pollDefinition[this.pollName];
    this.$store.commit('setCurrentPoll', {
        code: null,
        title: null,
    });
    if (poll) {
        this.$store.commit('setCurrentPoll', {
            code: this.pollName,
            title: poll.title,
        });
    }
    this.order = 'shuffle';
    this.searchCandidate = '';
    scrollToTop();
}

export default {
    props: ['pollName', 'candidateType'],
    directives: {
        debounce
    },
    data() {
        return {
            candidateFetchingInProgess: true,
            currentBodyClass: '',
            candidates: [],
            order: 'shuffle',
            inputFocus: false,
            searchString: '',
            searchCandidate: '',
        }
    },
    components: {
        AdUnit,
        IubendaConsentLinks,
        SvgIcon,
        recordCandidate: RecordCandidate,
        artistCandidate: ArtistCandidate,
        videoCandidate: VideoCandidate,
        creatorCandidate: CreatorCandidate,
        rockolAwardsHeader: Header,
        rockolAwardsFooter: Footer,
        pollDisclaimer: PollDisclaimer,
    },
    methods: {
        updateSearch() {
            const vm = this;
            lodashDebounce(
                function () {
                    vm.searchString = vm.searchCandidate;
                },
                2000
            );
        },
        candidateClaim(candidate) {
            if (candidate) {
                switch (candidate.type) {
                    case ARTIST_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.artist_name}'</strong>
                            `;
                    case RECORD_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.record_title}'</strong> di <strong>${candidate.artist_name}</strong>
                            `;
                    case VIDEO_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.title}'</strong> - <strong>'${candidate.artist_name}'</strong>
                            `;
                    case CREATOR_CANDIDATE_TYPE:
                        return `
                                <strong>'${candidate.creator_name}'</strong>
                            `;
                }
            }
            return null;

        }
    },
    computed: {
        isLoading() {
            return this.candidateFetchingInProgess || this.loginCheckInProgress
        },
        loginCheckInProgress() {
            return this.$store.getters.loginCheckInProgress
        },
        orderCandidates() {
            if (this.order === 'shuffle') {
                return shuffle(this.candidates);
            }
            if (this.order === 'alphabetical') {
                return this.candidates.concat().sort(function (a, b) {
                    var keyA = a.ordered_candidate,
                        keyB = b.ordered_candidate;
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }

        },
        candidateList() {
            let candidatesToShow = [];

            candidatesToShow = this.orderCandidates;

            if (this.userAlreadyVoted) {
                return candidatesToShow.filter(x => x.uid !== this.votedCandidateUid);
            }

            if (this.searchCandidate) {
                return candidatesToShow.filter((x) => {
                    const search = this.searchCandidate.toLowerCase();
                    const candidate = x.search_string.toLowerCase();
                    return candidate.indexOf(search) !== -1
                });
            }

            return candidatesToShow.filter(x => x.hilighted === false);
        },
        highlightedCandidate() {
            if (this.userAlreadyVoted) {
                return null;
            }
            return this.candidates.filter(x => x.hilighted === true)[0];
        },
        showPollControls() {
            return !this.votedCandidate && !this.highlightedCandidate;
        },
        votedCandidate() {
            if (this.userAlreadyVoted) {
                return this.candidates.filter(x => x.uid === this.votedCandidateUid)[0];
            }
            return null;
        },
        votedCandidateUid() {
            return get(this.$store.getters.userVoteState, 'votes[' + this.pollName + '].data.uid', null);
        },
        userAlreadyVoted() {
            return this.$store.getters.hasAlreadyVotedForThisPoll;
        },
        pollTitle() {
            if (pollDefinition && pollDefinition[this.pollName]) {
                return pollDefinition[this.pollName].title;
            }
            return null;
        },
        chosenCandidateCid() {
            return (this.$route.query && this.$route.query.cid) ?
                this.$route.query.cid
                :
                null;
        }

    },
    async created() {
        await getCandidates(this.pollName, this.chosenCandidateCid, this);
        setCurrentPollFromRoute.call(this);
    }
}
</script>
