<template>

    <section v-if="fetchInProgress">
        <h2>Recupero dei dati in corso</h2>
        <svg-icon icon="loading" :width="80" :height="80" cssClass="white-icon"></svg-icon>
    </section>
    <section v-else>
        <section v-if="!isVoteOpenForJury">
            <h2>Le votazioni per la giuria sono chiuse</h2>
            <p>In caso di problemi contattaci: platform@rockol.it</p>
        </section>
        <section v-if="isVoteOpenForJury">
            <div v-if="loginForm.isSubmitted && !loginForm.error">
                <div v-html="loginForm.message"></div>
                <router-link to="/" class="small">Vai all'homepage</router-link>
            </div>

            <div v-if="!loginForm.isSubmitted">
                <h2>Accesso riservato alla giuria di qualità</h2>

                <p>
                    Per accedere è sufficiente richiedere il link di accesso,
                    che ti arriverà tramite e-mail.
                </p>

                <form action="#" @submit.prevent="sendLoginLink">
                    <p v-if="loginForm.message"
                       :class="{
                            'text-error': loginForm.error,
                            'text-success': !loginForm.error,
                        }">{{ loginForm.message }}</p>

                    <section v-if="!loginForm.isSubmitted">
                        <div class="form-group">
                            <label>Il tuo indirizzo e-mail:</label>
                            <div v-if="userIdProvidedByQuerystring">
                                <strong class="emailAddress">{{ loginForm.model.email }}</strong>
                            </div>
                            <div v-else :class="{'has-error': $v.loginForm.model.email.$error}">
                                <input type="email" v-model="loginForm.model.email" class="form-control"
                                       placeholder="Indirizzo e-mail" :disabled="loginForm.isSubmitting">

                                <span class="text-danger" v-if="$v.loginForm.model.email.$error && !$v.loginForm.model.email.required">
                                Occorre compilare il campo
                            </span>
                                <span class="text-danger" v-if="$v.loginForm.model.email.$error && !$v.loginForm.model.email.email">
                                Inserire un e-mail valida
                            </span>
                            </div>
                        </div>

                        <br>

                        <div class="form-group">
                            <button type="submit"
                                    class="btn btn-logout small"
                                    :disabled="loginForm.isSubmitting">
                                <svg-icon icon="loading" v-if="loginForm.isSubmitting"></svg-icon>
                                Richiedi il link d'accesso
                            </button>
                        </div>

                    </section>
                </form>

            </div>
        </section>

    </section>
</template>

<script>
    import {getJurorByEmail, getJurorByIdAndSecret} from '../../../shared/juryApi'
    import SvgIcon from "../../shared/SvgIcon";
    import required from "vuelidate/lib/validators/required";
    import email from "vuelidate/lib/validators/email";
    import {mapGetters} from 'vuex';
    import sendErrorToSentry from "../../../shared/errorReporting";

    export default {
        async mounted() {
            if (this.$route.query.u && this.$route.query.secret) {
                this.query.userId = this.$route.query.u;
                this.query.secret = this.$route.query.secret;

                this.fetchInProgress = true;

                const jurorData = await getJurorByIdAndSecret(
                    this.query.userId,
                    this.query.secret
                );

                if (jurorData.data.email) {
                    this.loginForm.model.email = jurorData.data.email;
                    this.userIdProvidedByQuerystring = true;
                }

                this.fetchInProgress = false;
            }
        },
        components: {
            SvgIcon
        },
        data() {
            return {
                showLoginForm: true,
                showPasswordRecovery: false,
                fetchInProgress: false,
                query: {
                    userId: null,
                    secret: null,
                },
                userIdProvidedByQuerystring: null,
                loginForm: {
                    isSubmitting: false,
                    isSubmitted: false,
                    error: false,
                    message: '',
                    model: {
                        email: null,
                    }
                },
            }
        },
        validations: {
            loginForm: {
                model: {
                    email: {
                        required,
                        email
                    }
                }
            }
        },
        computed: {
            ...mapGetters(['isVoteOpenForJury'])
        },
        methods: {
            resetForm() {
                this.$v.$reset();
                this.loginForm.error = false;
                this.loginForm.message = '';
                this.loginForm.isSubmitting = true;
            },
            async sendLoginLink() {
                this.resetForm();

                this.$v.loginForm.model.$touch();
                if (this.$v.$error) {
                    this.loginForm.isSubmitting = false;
                    return;
                }

                this.loginForm.isSubmitting = true;
                try {
                    // Get userId and secret with email
                    const juror = await getJurorByEmail(this.loginForm.model.email);

                    if (!juror.data) {
                        throw {
                            publicMessage: 'Nessun giurato corrisponde all\'indirizzo fornito'
                        };
                    }

                    await this.$store.dispatch('jury/login/sendLoginLink', {
                        userEmail: this.loginForm.model.email,
                        userId: juror.data.id,
                    });

                    if (window.localStorage) {
                        window.localStorage.setItem('emailForSignIn', this.loginForm.model.email);
                    }

                    this.loginForm.isSubmitted = true;
                    this.loginForm.error = false;
                    this.loginForm.message = `
                    <h2 class="text-success">Ti abbiamo inviato l'e-mail contenente il link di accesso!</h2>
                    <p><strong>L'email ha come oggetto 'Accedi a MyRockol'.<br>Aprila e clicca il link che ha al suo interno.</strong></p>
                    <p>&nbsp;</p>
`;
                } catch (error) {
                    this.loginForm.error = true;
                    this.loginForm.message = error.publicMessage || 'C\'è stato un errore nella gestione della richiesta, siete pregati di riprovare.';
                } finally {
                    this.loginForm.isSubmitting = false;
                }

            }
        }
    }
</script>