<template>
    <div class="mt-2">
        <h4 v-if="candidateName" class="text-xs text-center">{{ candidateName }}</h4>
        <div class="mt-4 candidate_image" v-if="candidateImage"
             :style="{ 'background-image': 'url(' + candidateImage + ')' }"></div>

        <section v-if="!voteData && !userCannotVoteCurrentPoll">
            <!-- Route change -->
            <router-link
                    v-if="$store.state.currentPoll.code !== pollName"
                    :to="{name:pollName}" class="vote-button mt-4 text-sm">
                Vota!</router-link>

            <!-- Current poll, just close -->
            <button v-else class="vote-button mt-4 text-sm" @click="$emit('close-panel')">Vota!</button>
        </section>

        <div class="text-center" v-if="userCannotVoteCurrentPoll">Non votabile.</div>
    </div>
</template>

<script>

    import has from 'lodash/has';
    import {
        ARTIST_CANDIDATE_TYPE,
        VIDEO_CANDIDATE_TYPE,
        RECORD_CANDIDATE_TYPE,
        CREATOR_CANDIDATE_TYPE,
        pollDefinition,
    } from '../../shared/common';

    export default {
        props: ['pollName'],
        data() {
            return {}
        },
        computed: {
            userIsJuror() {
                return (this.$store.getters.getUser) ? this.$store.getters.getUser.isJuror : false;
            },
            userCannotVoteCurrentPoll() {
                const isNotVotable = pollDefinition[this.pollName].votableByJury === false && this.userIsJuror;
                return isNotVotable;
            },
            candidateType() {
                return pollDefinition[this.pollName].candidateType;
            },
            candidateImage() {
                switch (this.candidateType) {
                    case ARTIST_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.artist_data.artist_image : null;
                    case RECORD_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.record_cover : null;
                    case VIDEO_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.image_url : null;
                    case CREATOR_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.image_url : null
                }
            },
            candidateName() {
                switch (this.candidateType) {
                    case ARTIST_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.artist_data.artist_name : null;
                    case RECORD_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.release_title + " - " + this.voteData.artist_name : null;
                    case VIDEO_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.title + " - " + this.voteData.artist_name : null;
                    case CREATOR_CANDIDATE_TYPE:
                        return (this.voteData) ? this.voteData.creator_name : null
                }

            },
            route() {
                return pollDefinition[this.pollName].path;
            },
            voteData() {
                if (has(this.$store.getters, 'userVoteState.votes[' + this.pollName + '].data')) {
                    return this.$store.getters.userVoteState.votes[this.pollName].data;
                }
                return null;
            },
        }
    }
</script>

<style scoped>
    .candidate_image {
        background: transparent center center no-repeat;
        background-size: cover;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px solid white;
        display: block;
        margin: auto;
        margin-bottom: 10px;
        margin-top: 16px;
    }
</style>
