<template>
    <div>
        <svg v-if="icon == 'loading'" :width="iconWidth" :height="iconHeight" :class="cssClass"
             version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <path fill="currentColor"
                  d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
            <animateTransform attributeType="xml"
                              attributeName="transform"
                              type="rotate"
                              from="0 25 25"
                              to="360 25 25"
                              dur="0.6s"
                              repeatCount="indefinite"/>
            </path>
        </svg>


        <svg v-if="icon == 'vote-icon'" :width="iconWidth" :height="iconHeight" :class="cssClass"
             viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26t-55.5-65.5-68-97.5-53.5-121-23.5-138q0-220 127-344t351-124q62 0 126.5 21.5t120 58 95.5 68.5 76 68q36-36 76-68t95.5-68.5 120-58 126.5-21.5q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"/>
        </svg>

        <svg v-if="icon == 'share-icon'" :width="iconWidth" :height="iconHeight" :class="cssClass"
             viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1792 640q0 26-19 45l-512 512q-19 19-45 19t-45-19-19-45v-256h-224q-98 0-175.5 6t-154 21.5-133 42.5-105.5 69.5-80 101-48.5 138.5-17.5 181q0 55 5 123 0 6 2.5 23.5t2.5 26.5q0 15-8.5 25t-23.5 10q-16 0-28-17-7-9-13-22t-13.5-30-10.5-24q-127-285-127-451 0-199 53-333 162-403 875-403h224v-256q0-26 19-45t45-19 45 19l512 512q19 19 19 45z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        'icon': {}, 'width': {}, 'height': {}, 'cssClass': {
            default: 'fill-current'
        }
    },
    data() {
        return {
            iconWidth: this.width || 20,
            iconHeight: this.height || 20,
        }
    }
}
</script>
