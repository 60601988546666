<template>
    <div>
        <p class="big" v-if="candidateData === null">Ops! C'è stato un problema nel caricamento del candidato</p>
        <div class="container-fluid" v-else>
            <div class="row">
                <div class="col-xs-12 col-sm-6">
                    <div class="visible-xs">
                        <h2 class="detail__main-title">{{ candidateData.artist_name }}</h2>
                        <h3 class="detail__sub-title">{{ candidateData.title }}</h3>
                    </div>
                    <div class="videoWrapper">
                        <iframe width="560" height="349" frameborder="0" :src="youtubeVideoUrl"></iframe>
                    </div>
                    <div class="detail__category">
                        CATEGORIA: {{ pollTitle }}
                    </div>

                    <vote-button-group
                            :pollName="pollName"
                            :itemId="itemId"
                            :itemCid="itemCid"
                            :cssClass="'candidate-controls--condensed'"
                    ></vote-button-group>

                </div>
                <div class="col-xs-12 col-sm-6 text-left">


                    <div class="hidden-xs">
                        <h2 class="detail__main-title">{{ candidateData.artist_name }}</h2>
                        <h3 class="detail__sub-title">{{ candidateData.title }}</h3>

                    </div>
                    <br>
                    <p class="detail__fade">{{ candidateData.description }}</p>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import VoteButtonGroup from '../buttons/VoteButtonGroup.vue';

    export default {
        props: [
            "candidateData"
        ],
        components: {
            'vote-button-group': VoteButtonGroup,
        },
        computed: {
            itemId() {
                return this.candidateData.uid;
            },
            itemCid() {
                return this.candidateData.cid;
            },
            userAlreadyVoted() {
                return this.$store.getters.hasAlreadyVotedForThisPoll;
            },
            pollName() {
                return this.$store.getters.getCurrentPoll["code"];
            },
            pollTitle() {
                return this.$store.getters.getCurrentPoll["title"];
            },
            imageUrl() {
                return this.candidateData.image_url;
            },
            youtubeVideoUrl() {
                if (this.candidateData.youtube_id) {
                    return "https://www.youtube.com/embed/" + this.candidateData.youtube_id;
                }
                return null;
            }

        },
    }
</script>

<style lang="scss">
  .detail__fade {
    color: fade_out(white, .5);
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .videoWrapper iframe {
    border: 1px solid rgba(white, .3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
