var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('rockol-awards-header'),_c('rockol-awards-footer'),_vm._m(0),_c('div',{staticClass:"app-main-view app-home relative",class:_vm.$store.getters.withFooter},[_c('div',{},[_c('div',{staticClass:"relative mt-4"},[_c('div',{staticClass:"max-w-7xl mx-auto sm:px-6 lg:px-8"},[_c('div',{staticClass:"relative sm:overflow-hidden"},[_c('div',{staticClass:"relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 sm:pb-16 lg:pb-16 lg:px-8"},[_vm._m(1),_c('div',{staticClass:"mt-10 max-w-sm mx-auto sm:max-w-none flex justify-center"},[_c('div',{},[_c('renderless-login-button',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var loginCheckInProgress = ref.loginCheckInProgress;
                                            var userInitials = ref.userInitials;
                                            var userLoggedIn = ref.userLoggedIn;
                                            var user = ref.user;
                                            var displayName = ref.displayName;
                                            var showLogin = ref.showLogin;
                                            var openUserPanel = ref.openUserPanel;
return _c('div',{},[(!userLoggedIn && !loginCheckInProgress)?_c('button',{staticClass:"shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8",on:{"click":showLogin}},[_vm._v(" Accedi! ")]):_vm._e()])}}])})],1)])]),_c('ad-unit',{staticStyle:{"margin-bottom":"16px"},attrs:{"ad-type":"ad-masthead","ad-unit":0}}),_c('iubenda-consent-links')],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed inset-0"},[_c('div',{staticClass:"absolute inset-0 bg-current-theme"},[_c('img',{staticClass:"mix-blend-luminosity w-full h-full object-cover",attrs:{"src":require("./../../assets/img/bg-2021.png")}})]),_c('div',{staticClass:"absolute inset-0 bg-black opacity-60"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"},[_c('span',{staticClass:"block text-white"},[_vm._v("Ops! Pagina non trovata")]),_c('span',{staticClass:"block text-indigo-200"},[_vm._v("Non abbiamo trovato nessuna risorsa per l'url richiesto.")])])}]

export { render, staticRenderFns }