<template>
    <div class="candidate candidate--video" :class="{ 'candidateNotChosen': (userAlreadyVoted && !isVotedCandidate)}">

        <div class="candidate-mobile-box visible-xs">
            <h2 class="candidate-name">{{ candidate.artist_name }}</h2>
            <span class="candidate-subtitle">{{ candidate.title }}</span>
        </div>

        <figure @click.prevent="candidateDetails">
            <div class="box">
                <div class="content">
                    <div v-if="candidate.photo_big">
                        <img class="img-responsive"
                             :src="candidate.photo_big"
                             :srcset="responsiveImageSrcSet"
                             :sizes="responsiveImageSizes"
                             :title="candidate.candidateCompleteName"
                             :alt="candidate.candidateCompleteName">
                    </div>
                </div>

            </div>

            <div class="hidden-xs content-overlay"></div>

            <div class="hidden-xs content-details">
                <figcaption class="album-play-button">
                    <h2 class="candidate-hover-title">{{ candidate.artist_name }}</h2>
                    <h4 class="text-white"></h4>
                    <div class="candidate-hover-subtitle btn btn-link">
                        {{ candidate.title }}
                    </div>
                </figcaption>
            </div>
        </figure>

        <vote-button-group
                :pollName="pollName"
                :itemId="itemId"
                :itemCid="itemCid"
        ></vote-button-group>
    </div>
</template>

<script>
    import has from 'lodash/has';
    import VoteButtonGroup from '../buttons/VoteButtonGroup.vue';
    import {VIDEO_CANDIDATE_TYPE} from '../../../shared/common';

    export default {
        props: ['candidate'],
        data() {
            return {}
        },
        components: {
            'vote-button-group': VoteButtonGroup,
        },
        methods: {
            candidateDetails() {
                this.$store.dispatch('showCandidateDetails', {
                    type: VIDEO_CANDIDATE_TYPE,
                    id: this.candidate.uid
                })
            }
        },
        computed: {
            userCanVote() {
                return !this.userAlreadyVoted;
            },
            userAlreadyVoted() {
                return this.$store.getters.hasAlreadyVotedForThisPoll;
            },
            isVotedCandidate() {
                return !!(
                    this.userAlreadyVoted &&
                    has(this.$store.getters.userVoteState, 'votes[' + this.pollName + '].data.uid') &&
                    this.$store.getters.userVoteState.votes[this.pollName].data.uid === this.itemId
                );
            },
            pollName() {
                return this.$store.getters.currentPollCode
            },
            itemId() {
                return this.candidate.uid;
            },
            itemCid() {
                return this.candidate.cid;
            },
            candidateCompleteName() {
                return this.candidate.artist_name + " " + this.candidate.title;
            },
            responsiveImageSrcSet() {
                const srcset = [];
                if (this.candidate.photo_big) {
                    srcset.push(`${this.candidate.photo_big} 450w`)
                }
                if (this.candidate.photo_medium) {
                    srcset.push(`${this.candidate.photo_medium} 320w`)
                }
                if (this.candidate.photo_small) {
                    srcset.push(`${this.candidate.photo_small} 150w`)
                }
                return srcset.join(",");
            },
            responsiveImageSizes() {
                const sizes = [];
                sizes.push('(max-width: 320px) 150px');
                sizes.push('(max-width: 488px) 268px');
                sizes.push('(max-width: 767px) 428px');
                sizes.push('(max-width: 1600px) 453px');
                sizes.push('450px');
                return sizes.join(",");
            }
        }
    }
</script>

<style lang="scss">
</style>
