<template>
    <div class="fixed inset-0 text-white text-opacity-90 flex items-center justify-center" v-if="open">

        <div class="z-50 fixed top-0 sm:fixed sm:top-4 w-full sm:px-4">
            <div class="sm:h-20 mx-auto container max-w-5xl p-4 px-8 sm:rounded">
                <div class="flex space-x-8 items-center justify-between">
                    <div class="opacity-25">
                        <AwardsLogo :awards-year="awardsYear"/>
                    </div>

                    <div
                        class="px-3 py-2 md:text-lg "
                    >
                        <panel-close class="z-50" :onClose="closePanel"></panel-close>
                    </div>
                </div>
            </div>
        </div>

        <div class="absolute inset-0 z-1">
            <div class="absolute inset-0 bg-current-theme">
                <img
                    src="./../../assets/img/bg-2021.png"
                    class="opacity-25 mix-blend-luminosity w-full h-full object-cover">
            </div>
            <div class="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div class="absolute py-4 modal-view bottom-0 z-30 w-full overflow-auto grid place-items-center">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import PanelClose from "../shared/PanelClose";
import AwardsLogo from "./AwardsLogo";

export default {
    name: "ModalView",
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            awardsYear: process.env.VUE_APP_AWARDS_YEAR,
        }
    },
    components: {
        AwardsLogo,
        'panel-close': PanelClose
    },
    methods: {
        closePanel() {
            this.$emit('close-panel');
        }
    }
}
</script>
