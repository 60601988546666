import Vue from 'vue'
import Vuex from 'vuex'

import {juryModule} from './modules/jury'

import {state} from './state'
import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from './getters'
import createMutationsSharer from 'vuex-shared-mutations';

Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [
        createMutationsSharer(
            {
                predicate: [
                    'setUser',
                    'resetUserStatus',
                    'voteInProgress',
                    'setPollAsVoted'
                ]
            }
        )
    ],
    modules: {
        jury: juryModule
    },
    state,
    mutations,
    actions,
    getters
});
