<script>
export default {
    data: function () {
        return {}
    },
    render() {
        try {
            if (this.$scopedSlots.default) {
                const renderedSlot = this.$scopedSlots.default({
                    loginCheckInProgress: this.loginCheckInProgress,
                    userInitials: this.userInitials,
                    userLoggedIn: this.userLoggedIn,
                    user: this.user,
                    displayName: this.displayName,
                    showLogin: this.showLogin,
                    openUserPanel: this.openUserPanel,
                    logOut: this.logOut,
                });

                return renderedSlot;
            }
        } catch (err) {
            console.error(err)
        }

        return null;
    },
    computed: {

        loginCheckInProgress() {
            return this.$store.getters.loginCheckInProgress;
        },

        userInitials() {
            let displayName = 'USR';

            if (this.displayName) {
                displayName = this.displayName.split(" ").map((x) => x.split('')[0]).join('');
            }

            return displayName;
        },
        userLoggedIn() {
            return this.$store.getters.userLoggedIn;
        },
        user() {
            return this.$store.getters.getUser;
        },
        displayName() {
            return this.$store.getters.userDisplayName
        }
    },
    methods: {
        showLogin() {
            this.$store.commit("showLoginPanel");
            this.$store.commit('refreshAdv');
        },
        openUserPanel() {
            this.$store.commit("showUserPanel");
        },
        logOut() {
            this.$store.dispatch("userSignOut");
        },
    }
}
</script>
