import {login} from "./juryLogin";
import {resetPassword} from "./juryResetPassword";
import {onBoarding} from "./juryOnboarding";

export const juryModule = {
    modules: {
        login,
        resetPassword,
        onBoarding,
    },
    namespaced: true,
};