export default function User(
    userAuthObject,
    savedProfile = {},
    consents = {},
    isJuror = false,
) {

    const userAuthData = userAuthObject.user ? userAuthObject.user : userAuthObject;

    return {
        uid: getProperty('uid'),
        firstName: getProperty('firstName'),
        lastName: getProperty('lastName'),
        displayName: getProperty('displayName'),
        email: getProperty('email'),
        photoURL: getProperty('photoURL'),
        authToken: getProperty('authToken'),
        consents,
        isJuror: isJuror,
    };

    function getProperty(propertyName) {
        if (savedProfile[propertyName]) {
            return savedProfile[propertyName]
        }

        if (userAuthData[propertyName]) {
            return userAuthData[propertyName];
        }

        if (((userAuthData.providerData || [{}])[0])[propertyName]) {
            return userAuthData.providerData[0][propertyName];
        }

        return null;
    }
}
