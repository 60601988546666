<template>
    <div>
        <p class="big" v-if="candidateData === null">Ops! C'è stato un problema nel caricamento del candidato</p>
        <div class="container-fluid" v-else>
            <div class="row">
                <div class="col-xs-12 col-sm-6">
                    <h2 class="visible-xs detail__main-title">{{ candidateData.creator_name }}</h2>

                    <img :src="imageUrl" class="detail__image">

                    <div class="detail__category">
                        CATEGORIA: {{ pollTitle }}
                    </div>

                    <p v-if="userIsJuror"><br>Non votabile dalla giuria</p>
                    <div class="candidate-controls candidate-controls--condensed"
                         :class="{ centered: userAlreadyVoted || userIsJuror }">

                        <vote-button v-if="userCanVote" :pollName="pollName" :itemId="itemId"></vote-button>
                        <share-button :pollName="pollName" :itemId="itemId" :itemCid="itemCid"></share-button>
                    </div>

                </div>
                <div class="col-xs-12 col-sm-6 text-left">
                    <div class="hidden-xs">
                        <h2 class="detail__main-title">{{ candidateData.creator_name }}</h2>
                        Canale Youtube: <a :href="candidateData.youtube_channel_url" target="_blank" rel="noopener noreferrer">{{ youtubeChannelUrl }}</a>
                    </div>
                    <br>
                    <p class="detail__fade">{{ candidateData.description }}</p>
                </div>
            </div>


        </div>

    </div>
</template>

<script>
    import VoteButton from '../buttons/VoteButton.vue';
    import ShareButton from '../buttons/ShareButton.vue';

    export default {
        props: [
            "candidateData"
        ],
        components: {
            'vote-button': VoteButton,
            'share-button': ShareButton,
        },
        computed: {
            userIsJuror() {
                return (this.$store.getters.getUser) ? this.$store.getters.getUser.isJuror : false;
            },
            userCanVote() {
                return !this.userAlreadyVoted && !this.userIsJuror;
            },
            itemId() {
                return this.candidateData.uid;
            },
            itemCid() {
                return this.candidateData.cid;
            },
            userAlreadyVoted() {
                return this.$store.getters.hasAlreadyVotedForThisPoll;
            },
            pollName() {
                return this.$store.getters.getCurrentPoll["code"];
            },
            pollTitle() {
                return this.$store.getters.getCurrentPoll["title"];
            },
            youtubeChannelUrl() {
              if (this.candidateData.youtube_channel_url) {
                  const url = this.candidateData.youtube_channel_url;
                  return (url.length > 40) ? url.substring(0, 40) + "..." : url;
              }
              return null;
            },
            imageUrl() {
                return this.candidateData.image_url;
            },
            youtubeVideoUrl() {
                if (this.candidateData.youtube_id) {
                    return "https://www.youtube.com/embed/" + this.candidateData.youtube_id;
                }
                return null;
            }

        },
    }
</script>

<style lang="scss">
  .detail__fade {
    color: fade_out(white, .5);
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .videoWrapper iframe {
    border: 1px solid rgba(white, .3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
