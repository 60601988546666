<template>
    <div class="flex flex-col justify-center items-center"
         :class="{ 'opacity-40': (userAlreadyVoted && !isVotedCandidate)}">

        <div class="text-center mb-4 sm:hidden sm:mb-4">
            <h2 class="font-black leading-none text-4xl uppercase">{{ candidate.artist_name }}</h2>
        </div>

        <figure @click.prevent="candidateDetails"
                class="mb-4">
            <div class="box">
                <div class="content">
                    <button v-if="candidate.photo_big"
                            class="shadow-lg hover:transform transition-all duration-100 group relative bg-gray-100  overflow-hidden flex flex-col">
                        <div class="z-0 relative bg-red-500 aspect-w-1 aspect-h-1" style="width:300px;">
                            <div class="absolute inset-0 z-0 overflow-hidden">
                                <div class="absolute z-10 inset-0 opacity-70 bg-black"></div>
                                <img
                                    class="w-full h-full object-cover bg-center filter blur-sm transform rotate-6 scale-150"
                                    loading="lazy"
                                    :src="candidate.photo_big"
                                    :srcset="responsiveImageSrcSet"
                                    :sizes="responsiveImageSizes"
                                    :title="candidate.candidateCompleteName"
                                    :alt="candidate.candidateCompleteName"/>

                            </div>
                            <div class="absolute inset-0 flex items-center justify-center pb-16">
                                <img
                                    class="relative w-full h-full opacity-100  object-contain bg-center"
                                    loading="lazy"
                                    :src="candidate.photo_big"
                                    :srcset="responsiveImageSrcSet"
                                    :sizes="responsiveImageSizes"
                                    :title="candidate.candidateCompleteName"
                                    :alt="candidate.candidateCompleteName"/>
                            </div>
                            <div>
                                <div
                                    class="absolute inset-0 bg-current-theme group-hover:bg-transparent opacity-25 transition-all duration-500"></div>
                                <section
                                    class="absolute z-20 bottom-0 px-4 py-6 group-hover:pt-8 group-hover:pb-14 inset-x-0 text-white transition-all duration-300 ease-in-out">
                                    <div class="absolute bg-black opacity-80 inset-0"></div>
                                    <div class="relative">
                                        <h1 class="font-bold text-center uppercase text-xl leading-none uppercase">
                                            {{ candidate.artist_name }}</h1>
                                    </div>
                                </section>
                                <section
                                    class="z-20 text-current-theme absolute uppercase tracking-widest font-semibold p-4 text-xs inset-x-0 bottom-0 transition-all duration-100 delay-100 opacity-0 group-hover:opacity-75 flex items-center justify-center">
                                    Scopri
                                </section>
                            </div>
                        </div>
                    </button>
                </div>

            </div>

        </figure>

        <vote-button-group :pollName="pollName" :itemId="itemId" :itemCid="itemCid"></vote-button-group>
    </div>
</template>

<script>
import VoteButtonGroup from '../buttons/VoteButtonGroup.vue';
import {ARTIST_CANDIDATE_TYPE} from '../../../shared/common';
import has from 'lodash/has';

export default {
    props: ['candidate'],
    data() {
        return {}
    },
    components: {
        'vote-button-group': VoteButtonGroup,
    },
    methods: {
        candidateDetails() {
            this.$store.dispatch('showCandidateDetails', {
                type: ARTIST_CANDIDATE_TYPE,
                id: this.candidate.uid
            })
        }
    },
    computed: {
        userCanVote() {
            return !this.userAlreadyVoted;
        },
        userAlreadyVoted() {
            return this.$store.getters.hasAlreadyVotedForThisPoll;
        },
        isVotedCandidate() {
            return !!(
                this.userAlreadyVoted &&
                has(this.$store.getters.userVoteState, 'votes[' + this.pollName + '].data.uid') &&
                this.$store.getters.userVoteState.votes[this.pollName].data.uid === this.itemId
            );
        },
        pollName() {
            return this.$store.getters.currentPollCode
        },
        itemId() {
            return this.candidate.uid;
        },
        itemCid() {
            return this.candidate.cid;
        },
        candidateCompleteName() {
            return this.candidate.artist_name;
        },
        responsiveImageSrcSet() {
            const srcset = [];
            if (this.candidate.photo_big) {
                srcset.push(`${this.candidate.photo_big} 450w`)
            }
            if (this.candidate.photo_medium) {
                srcset.push(`${this.candidate.photo_medium} 320w`)
            }
            if (this.candidate.photo_small) {
                srcset.push(`${this.candidate.photo_small} 150w`)
            }
            return srcset.join(",");
        },
        responsiveImageSizes() {
            const sizes = [];
            sizes.push('(max-width: 320px) 170px');
            sizes.push('(max-width: 488px) 268px');
            sizes.push('(max-width: 767px) 442px');
            sizes.push('(max-width: 989px) 304px');
            sizes.push('(max-width: 1600px) 304px');
            sizes.push('450px');
            return sizes.join(",");
        }
    }
}
</script>

<style lang="scss">
</style>
