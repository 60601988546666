<template>
    <div class="text-current-theme">
        <!-- Voto aperto -->
        <div class="flex justify-center inline-center space-x-4"
             :class="cssClassToApply"
             v-if="isVoteOpen">

            <div v-if="canBeVoted">
                <vote-button v-if="userCanVote" :pollName="pollName" :itemId="itemId"></vote-button>
            </div>
            <div v-else>
                <span class="votable-since">Votabile dal <br><strong>{{ votableDate }}</strong></span>
            </div>

            <share-button :pollName="pollName" :itemId="itemId" :itemCid="itemCid"></share-button>

        </div>
        <!-- Votazioni chiuse -->
        <div class="candidate-controls text-xs font-semibold uppercase tracking-wider text-white opacity-80" v-else>

            <div class="mx-auto">
                <span v-if="voteForUserHasNotBeenStarted">Votazioni non ancora aperte</span>
                <span v-if="voteForUserHasEnded">Votazioni chiuse</span>
            </div>

        </div>
    </div>
</template>


<script>
    import VoteButton from './VoteButton.vue';
    import ShareButton from './ShareButton.vue';
    import {mapGetters} from 'vuex';

    export default {
        props: [
            'pollName',
            'itemId',
            'itemCid',
            'cssClass',
            'elementIsVotable',
            'votableDate'
        ],
        data() {
            return {
                currentButton: false,
                helpMessage: '',
            }
        },
        components: {
            'vote-button': VoteButton,
            'share-button': ShareButton,
        },
        computed: {
            ...mapGetters(['loggedInUserIsJuror', 'isVoteOpen', 'voteForUserHasNotBeenStarted', 'voteForUserHasEnded']),
            canBeVoted() {
                const votable = typeof this.elementIsVotable === 'undefined' || this.elementIsVotable === true;
                return this.loggedInUserIsJuror || votable;
            },
            userCanVote() {
                return !this.userAlreadyVoted;
            },
            userAlreadyVoted() {
                return this.$store.getters.hasAlreadyVotedForThisPoll;
            },
            cssClassToApply() {
                var classes = [];
                if (this.cssClass) {
                    classes.push(this.cssClass);
                }
                if (this.userAlreadyVoted) {
                    classes.push('centered');
                }
                return classes.join(' ');
            }
        },
        methods: {}
    }
</script>
