<template>
    <div>
        <div class="modal-panel" v-if="userNeedConsents">
            <div class="overlay-panel__inner">
                <div class="overlay-panel__main-content">
                    <div>
                        <h1>Rockol Awards</h1>

                        <p>
                            Grazie per aver effettuato l'accesso, per poter partecipare
                            alla votazione abbiamo bisogno dei seguenti consensi:
                        </p>

                        <p>&nbsp;</p>

                        <span class="text-error" v-if="$v.$error">
                            E' necessario accettare i consensi richiesti.
                        </span>

                        <label v-if="!userConsents.privacy"
                               class="checkbox"
                               :class="{'has-error': $v.loginForm.model.privacy_consent.$error}">
                            <input
                                    type="checkbox"
                                    name="privacy_consent"
                                    value="1"
                                    v-model="loginForm.model.privacy_consent"
                                    @change="$v.$dirty ? $v.loginForm.model.privacy_consent.$touch() : () => {}">
                            Accetto i
                            <a href="https://www.rockol.it/info/privacy"
                               target="_blank"
                               class="underline"
                               rel="nopener noreferrer">Termini di servizio</a>
                        </label>

                        <label v-if="!userConsents.awards"
                               class="checkbox"
                               :class="{'has-error': $v.loginForm.model.awards_consent.$error}">
                            <input
                                    type="checkbox"
                                    name="awards_consent"
                                    value="1"
                                    v-model="loginForm.model.awards_consent"
                                    @change="$v.$dirty ? $v.loginForm.model.awards_consent.$touch() : () => {}">
                            Accetto di partecipare ai Rockol Awards e di esprimere il mio voto
                        </label>

                        <p>&nbsp;</p>

                        <a href="" class="btn btn-logout" @click.prevent="activateConsents()">
                            <svg-icon icon="loading" v-if="loginForm.isSubmitting"></svg-icon>
                            Do il consenso
                        </a>

                        <hr>
                        Se non vuoi partecipare alle&nbsp;votazioni &nbsp;&nbsp;
                        <a href="" class="btn btn-secondary" @click.prevent="$store.dispatch('userSignOut')">Esci</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import PanelClose from '../shared/PanelClose.vue'
    import {mapGetters} from 'vuex'
    import SvgIcon from "../shared/SvgIcon";

    export default {
        components: {
            'panel-close': PanelClose,
            SvgIcon
        },
        data() {
            return {
                iconsHeight: 120,
                loginForm: {
                    isSubmitting: false,
                    isSubmitted: false,
                    model: {
                        privacy_consent: false,
                        awards_consent: false
                    }
                }
            }
        },
        watch: {
            userConsents(consents) {
                if (consents.privacy) {
                    this.loginForm.model.privacy_consent = true;
                }
                if (consents.awards) {
                    this.loginForm.model.awards_consent = true;
                }
            }
        },
        validations: {
            loginForm: {
                model: {
                    privacy_consent: {
                        checked: (val) => {
                            return val === true;
                        }
                    },
                    awards_consent: {
                        checked: (val) => {
                            return val === true;
                        }
                    },
                }
            }
        },
        computed: {
            ...mapGetters({
                'userConsents': 'getUserConsents',
                'userNeedConsents': 'userNeedConsents',
            }),
        },
        methods: {
            async activateConsents() {
                this.$v.loginForm.model.$touch();

                if (this.$v.$error) {
                    this.loginForm.isSubmitting = false;
                    return;
                }

                this.loginForm.isSubmitting = true;
                await this.$store.dispatch('addUserConsents', this.$store.getters.getUser.uid);
                this.loginForm.isSubmitting = false;
                this.loginForm.isSubmitted = true;
            }
        }
    }
</script>

<style lang="scss">
    .detail__fade {
        color: fade_out(white, .5);
    }
</style>
