import axios from 'axios';
import qs from 'qs'
import {candidatesApiSettings, juryApiSettings} from './settings'

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

export const getFirebaseDb = () => {
    return firebase.database();
};

export const getCandidateDetailFromApi = async (requestType, entityId) => {
    const separator = candidatesApiSettings.basePath.indexOf('?') !== -1 ? "&" : "?";
    const url = candidatesApiSettings.host + candidatesApiSettings.basePath + separator
        + "requestType=candidateDetail"
        + "&type=" + requestType
        + "&id=" + entityId
        + "&output=json";

    return axios.get(url)
};


export const getCandidateType = (pollName) => {
    return pollDefinition[pollName].candidateType;
};

export const userIsJuror = (firebaseUid) => {
    const separator = juryApiSettings.basePath.indexOf('?') !== -1 ? "&" : "?";
    return axios({
        method: 'post',
        url: juryApiSettings.host + juryApiSettings.basePath + separator + "output=json&requestType=userIsJuror",
        headers: candidatesApiSettings.defaultHeaders,
        data: qs.stringify({
            firebaseUid: firebaseUid,
        })
    }).then((result) => {
        return (result && result.data && result.data.isJuror);
    });
};

export const userTokenIsValid = (uid) => {
    const tokenPromise = firebase.auth().currentUser
        .getIdToken()
        .then((token) => {
            return checkTokenApiCall(token, uid);
        })
        .then((response) => {
            const tokenValid = response && response.data && response.data.valid === true;
            return tokenValid;
        })
        .catch((err) => {
            return false;
        });

    const checkTokenApiCall = (token, uid) => {
        return axios({
            method: 'post',
            url: juryApiSettings.host + juryApiSettings.basePath + "?output=json&requestType=checkFirebaseToken",
            headers: candidatesApiSettings.defaultHeaders,
            data: qs.stringify({
                u: uid,
                token
            })
        });
    };

    return tokenPromise;
};


export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const BEST_ITALIAN_RECORDS_POLL_NAME = 'best_italian_records';
export const BEST_INTERNATIONAL_RECORDS_POLL_NAME = 'best_international_records';
export const BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME = 'best_international_artist_shows';
export const BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME = 'best_italian_artist_shows';
export const BEST_VIDEO_POLL_NAME = 'best_video';
export const BEST_CREATORS_POLL_NAME = 'best_creators';

export const RECORD_CANDIDATE_TYPE = 'record';
export const ARTIST_CANDIDATE_TYPE = 'artist';
export const VIDEO_CANDIDATE_TYPE = 'video';
export const CREATOR_CANDIDATE_TYPE = 'creator';

export const pollDefinition = {
    [BEST_ITALIAN_RECORDS_POLL_NAME]: {
        title: "Miglior album italiano",
        path: "/miglior-album-italiano",
        candidateType: RECORD_CANDIDATE_TYPE,
        votableByJury: true
    },
    [BEST_INTERNATIONAL_RECORDS_POLL_NAME]: {
        title: "Miglior album internazionale",
        path: "/miglior-album-internazionale",
        candidateType: RECORD_CANDIDATE_TYPE,
        votableByJury: true
    },
    [BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME]: {
        title: "Miglior artista live internazionale",
        path: "/miglior-artista-live-internazionale",
        candidateType: ARTIST_CANDIDATE_TYPE,
        votableByJury: true
    },
    [BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME]: {
        title: "Miglior artista live italiano",
        path: "/miglior-artista-live-italiano",
        candidateType: ARTIST_CANDIDATE_TYPE,
        votableByJury: true
    },
    [BEST_VIDEO_POLL_NAME]: {
        title: "Miglior video",
        path: "/miglior-video",
        candidateType: VIDEO_CANDIDATE_TYPE,
        votableByJury: true
    },
    /*
    [BEST_CREATORS_POLL_NAME]: {
        title: "Migliori creators",
        path: "/migliori-creators",
        candidateType: CREATOR_CANDIDATE_TYPE,
        votableByJury: false
    }
    */
};

let currentScrollTop = 0;
export const bodyScroll = (scrollActive, makeBodyVisible = true) => {
    const body = document.getElementsByTagName('body')[0];
    const bodyViewDiv = document.querySelector('#page-view');
    const classLists = body.classList;

    let bodyViewVisible = true;
    classLists.remove("noscroll");
    if (scrollActive === false) {
        currentScrollTop = document.documentElement.scrollTop;
        classLists.add("noscroll");
        bodyViewVisible = false;
    }

    if (makeBodyVisible) {
        bodyViewDiv.style.display = bodyViewVisible ? 'block' : 'none';
        if (bodyViewVisible) {
            document.documentElement.scrollTop = currentScrollTop;
        }
    }
};

export const getCandidatesPromise = (pollName) => {

    const separator = candidatesApiSettings.basePath.indexOf('?') !== -1 ? "&" : "?";
    const url = candidatesApiSettings.host + candidatesApiSettings.basePath + separator
        + "requestType=candidates"
        + "&poll="
        + pollName
        + "&output=json";

    return axios.get(url);
};

export const getValidPolls = (configuredPolls = '') => {
    const polls = configuredPolls.split(',');
    return Object.keys(pollDefinition).reduce((acc, key) => {
        if (polls.indexOf(key) !== -1) {
            acc[key] = pollDefinition[key];
        }
        return acc;
    }, {});
}
