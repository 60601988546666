<template>
    <div class="mx-auto max-w-4xl pb-8">
        <div class="opacity-80 rules-container text-white space-y-4 mx-auto max-w-2xl">
            <h2 class="text-center font-black text-2xl">Regolamento e disclaimer</h2>

            <p>
                Per costituire il panel dei candidati votabili abbiamo preso in considerazione nuovi album e
                concerti/tour, di artisti italiani usciti o avvenuti nel periodo che va dal 1° novembre 2023 al 31 ottobre 2024.<br>
                All'interno di questo arco temporale sono stati selezionati dalla redazione di Rockol 35 album e 35
                concerti. Per la categoria album sono state considerate solo pubblicazioni con nuove canzoni. Non sono
                state considerate ristampe, edizioni speciali di album originariamente usciti fuori dal periodo, dischi
                dal vivo, raccolte ed EP. Per la sezione concerti, invece, sono stati considerati sia tour che concerti
                singoli.
            </p>

            <p>
                <strong>Poche semplici avvertenze:</strong>
            </p>

            <ul class="list list-disc pl-4">
                <li>
                    <span class="font-semibold underline">Non è possibile</span> cambiare un voto una volta attribuito
                </li>
                <li>
                    <span class="font-semibold underline">E’ possibile</span> completare la votazione in momenti diversi
                    (ad esempio votando oggi il Miglior
                    Concerto e in un secondo momento il Miglior Album, e viceversa).
                </li>
            </ul>

            <p>
                E’ possibile votare dalle ore {{ hourStart }} del {{ dateStart }} alle {{ hourEnd }} del {{ dateEnd }}.
                Si potrà esprimere un solo voto per ogni categoria: la moltiplicazione di identità tramite account email
                fittizi effettuata in maniera sistematica e organizzata potrà comportare l'annullamento del voto
                espresso per mezzo degli stessi.
            </p>

            <h2 class="text-center font-black text-2xl">Per segnalazioni e domande</h2>

            <p class="text-center">
                Potete contattarci all'indirizzo <strong>awards@rockol.it</strong>
            </p>

        </div>
    </div>
</template>

<script>
import format from 'date-fns/format'

export default {
    computed: {
        dateStart() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.start), 'DD/MM/YYYY')
        },
        hourStart() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.end), 'HH:mm')
        },
        dateEnd() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.end), 'DD/MM/YYYY')
        },
        hourEnd() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.end), 'HH:mm')
        }

    }
}
</script>

