<template>
    <div class="text-center text-xs font-semibold" style="width: 105px;">
        <div class="h-10">
            <img src="../../assets/img/rockol-awards-logo-white.png"
                 class="object-contain w-full h-full">
        </div>
        {{ awardsYear }}
        <span class="sr-only">Rockol Awards {{ awardsYear }}</span>
    </div>
</template>

<script>
export default {
    name: 'AwardsLogo',
    props: {
        awardsYear: {}
    }
}
</script>
