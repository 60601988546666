<template>
    <div>
        <rockol-awards-header></rockol-awards-header>
        <rockol-awards-footer></rockol-awards-footer>

        <div class="fixed inset-0">
            <div class="absolute inset-0 bg-current-theme">
                <img
                    src="./../../assets/img/bg-2021.png"
                    class="mix-blend-luminosity w-full h-full object-cover">
            </div>
            <div class="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div class="app-main-view app-home relative" :class="$store.getters.withFooter">

            <div class="">
                <!-- Hero card -->
                <div class="relative mt-4">
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div class="relative sm:overflow-hidden">
                            <div class="relative px-4 py-16 sm:px-6 sm:py-16 lg:py-16 sm:pb-16 lg:pb-16 lg:px-8">
                                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                    <span class="block text-white">Rockol Awards</span>
                                    <span class="block text-indigo-200">Edizione {{ awardsYear }}</span>
                                </h1>

                                <div v-if="voteForUserHasEnded && !showLiveStreaming">
                                    <p class="mt-6 max-w-lg mx-auto text-center  text-white sm:max-w-3xl">
                                        <span class="text-indigo-200 text-2xl mb-2 inline-block">Le votazioni sono terminate!</span>
                                        <br>
                                        I vincitori saranno annunciati durante la serata di premiazione in data
                                        <strong>{{ liveEventDate }}</strong>.<br>
                                    </p>
                                </div>

                                <div v-if="showLiveStreaming" class="mt-8 max-w-xl mx-auto">
                                    <div class="aspect-w-16 aspect-h-9 border border-solid border-white">
                                        <iframe
                                            class="w-full h-full"
                                            :src="liveStreaming.url"
                                            title="Rockol Awards 2022"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        ></iframe>
                                    </div>
                                </div>

                                <div v-if="voteForUserHasNotBeenStarted">
                                    <p class="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                                        Vota il tuo album e il tuo live preferiti dell’anno
                                    </p>
                                    <div>
                                        <p class="mt-6 max-w-lg mx-auto text-center text-lg font-semibold text-white sm:max-w-3xl">
                                            70 candidati, 2 categorie, vota a partire dal {{ dateStart }} alle
                                            {{ hourStart }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="!voteForUserHasNotBeenStarted && !isVoteClosed"
                                     class="mt-10 max-w-sm mx-auto sm:max-w-none flex justify-center">
                                    <div class="">
                                        <renderless-login-button>
                                            <div slot-scope="{
                                                    loginCheckInProgress,
                                                    userInitials,
                                                    userLoggedIn,
                                                    user,
                                                    displayName,
                                                    showLogin,
                                                }">
                                                <button v-if="!userLoggedIn && !loginCheckInProgress"
                                                        @click="showLogin"
                                                        class="shadow-lg flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8">
                                                    Accedi!
                                                </button>

                                                <div v-else>
                                                    <div
                                                        class="flex flex-col flex-1 space-y-4 items-center justify-center text-white">
                                                        <router-link
                                                            v-if="useVueRouter && (pollsActive.indexOf('best_italian_records') !== -1)"
                                                            :to="{ name: 'best_italian_records' }"
                                                            class="underline text-center leading-none font-bold text-xl bg-gradient-to-tr transition-all transform hover:scale-110  duration-100"
                                                        >Vota per il miglior Album
                                                        </router-link>
                                                        <a :href="makeRoute('/miglior-album-italiano')" v-else
                                                           class="underline text-center leading-none font-bold text-xl bg-gradient-to-tr transition-all transform hover:scale-110  duration-100">
                                                            Vota per il miglior Album
                                                        </a>

                                                        <router-link
                                                            v-if="useVueRouter && (pollsActive.indexOf('best_italian_artist_shows') !== -1)"
                                                            :to="{ name: 'best_italian_artist_shows' }"
                                                            class="underline text-center leading-none font-bold text-xl transition-all transform hover:scale-105 duration-100"
                                                        >Vota per il miglior Live
                                                        </router-link>
                                                        <a :href="makeRoute('/miglior-artista-live-italiano')" v-else
                                                           class="underline text-center leading-none font-bold text-xl bg-gradient-to-tr transition-all transform hover:scale-110  duration-100">
                                                            Vota per il miglior Live
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </renderless-login-button>
                                    </div>
                                </div>
                            </div>

                            <ad-unit ad-type="ad-masthead" :ad-unit="0" style="margin-bottom: 16px;"></ad-unit>

                            <iubenda-consent-links></iubenda-consent-links>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../page-components/Header.vue';
import Footer from '../page-components/Footer.vue';
import concat from 'lodash/concat';
import map from 'lodash/map';
import shuffle from 'lodash/shuffle';
import axios from 'axios';
import {
    BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME,
    BEST_INTERNATIONAL_RECORDS_POLL_NAME,
    BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME,
    BEST_ITALIAN_RECORDS_POLL_NAME,
    getCandidatesPromise,
    getValidPolls
} from '../../shared/common';
import IubendaConsentLinks from "../shared/IubendaConsentLinks";
import {mapGetters} from "vuex";
import RenderlessLoginButton from "../user/RenderlessLoginButton";
import AdUnit from "../shared/AdUnit";
import format from "date-fns/format";

export default {
    data: function () {
        return {
            awardsYear: process.env.VUE_APP_AWARDS_YEAR,
            relativePath: process.env.VUE_APP_RELATIVE_PATH || '/',
            useVueRouter: (process.env.VUE_APP_USE_VUE_ROUTER || 'yes') === 'yes',
            liveStreaming: this.$store.state.app.liveStreaming,
            tileCompleteList: [],
            photosDimensions: 0,
            tilesToDisplay: 0,
            pollsActive: Object.keys(getValidPolls(process.env.VUE_APP_POLLS_SECTIONS || ''))
        }
    },
    methods: {
        makeRoute(route = '') {
            return (this.relativePath + route).replace(/\/\//g, "/");
        },
    },
    computed: {
        ...mapGetters(['isVoteClosed', 'voteForUserHasNotBeenStarted', 'voteForUserHasEnded']),
        showLiveStreaming() {
            if (!this.$store.state.app.liveStreaming.start || !this.$store.state.app.liveStreaming.end) {
                return false;
            }

            return (
                this.$store.state.app.currentTimestamp > this.$store.state.app.liveStreaming.start &&
                this.$store.state.app.currentTimestamp < this.$store.state.app.liveStreaming.end
            );
        },
        tileList() {
            return this.tileCompleteList.slice(0, this.tilesToDisplay);
        },
        dateStart() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.start), 'DD/MM/YYYY')
        },
        hourStart() {
            return format(new Date(this.$store.state.app.voteDatesForUsers.start), 'hh:mm')
        },
        liveEventDate() {
            return format(new Date(this.$store.state.app.liveEventDate), 'DD/MM/YYYY')
        },
    },
    components: {
        AdUnit,
        RenderlessLoginButton,
        IubendaConsentLinks,
        rockolAwardsHeader: Header,
        rockolAwardsFooter: Footer,
    },
    created() {
        const vm = this;
        // Get all the candidates images
        axios.all([
            getCandidatesPromise(BEST_ITALIAN_RECORDS_POLL_NAME),
            getCandidatesPromise(BEST_INTERNATIONAL_RECORDS_POLL_NAME),
            getCandidatesPromise(BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME),
            getCandidatesPromise(BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME)
        ]).then(
            axios.spread((one, two, three, four) => {
                const data = concat(one.data, two.data, three.data, four.data);
                const images = shuffle(map(data, 'photo_small'));
                vm.tileCompleteList = images;
            })
        );
    },
    mounted() {
        this.$nextTick(() => {
            this.$root.$emit('start-adv');
        });
    }
}
</script>

