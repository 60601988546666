<template>
    <div>
        <p class="big" v-if="candidateData === null">Ops! C'è stato un problema nel caricamento del candidato</p>
        <div v-else>
            <div class="sm:flex space-x-4">
                <div class="w-full sm:w-1/2">
                    <div class="sm:hidden mb-4 sm:mb-0 pt-12 sm:pt-0">
                        <h2 class="text-2xl font-black text-white text-center uppercase">{{ candidateData.artist_data.artist_name }}</h2>
                    </div>

                    <div class="px-8 w-full aspect-w-4 aspect-h-3">
                        <img :src="imageUrl" class="w-full object-contain ">
                    </div>

                    <div class="font-bold text-center mt-4 ">
                        CATEGORIA: {{ pollTitle }}
                    </div>

                    <div class="mt-4 flex items-center justify-center">
                        <vote-button-group
                            :pollName="pollName"
                            :itemId="itemId"
                            :itemCid="itemCid"
                            :cssClass="'candidate-controls--condensed'"
                        ></vote-button-group>
                    </div>

                    <div class="my-8">
                        <ad-unit ad-type="ad-lazy-load" :is-lazy-loaded="true" />
                    </div>
                </div>

                <div >
                    <div class="hidden sm:block">
                        <h2 class="text-2xl font-black text-white uppercase">{{ candidateData.artist_data.artist_name }}</h2>
                    </div>
                    <div v-if="candidateData.concerts" class="mt-8 sm:mt-4 text-sm opacity-90">
                        <h3 class="text-center sm:text-left  font-semibold text-xl text-white">Concerti</h3>
                        <table class="table text-xs mt-2">
                            <tr v-for="(concert,idx) in candidateData.concerts" :key="idx">
                                <td align="right" class="text-right pr-1">{{ concert.date }}</td>
                                <td class="p-1">{{ concert.venue }}</td>
                                <td align="right" class="text-right p-1 opacity-50">{{ concert.place }}</td>
                                <td class="p-1 opacity-50 text-center">({{ concert.state }})</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VoteButtonGroup from '../buttons/VoteButtonGroup.vue';
import AdUnit from "../../shared/AdUnit";

export default {
    props: [
        "candidateData"
    ],
    components: {
        AdUnit,
        'vote-button-group': VoteButtonGroup,
    },
    computed: {
        itemId() {
            return this.candidateData.uid;
        },
        itemCid() {
            return this.candidateData.cid;
        },
        userAlreadyVoted() {
            return this.$store.getters.hasAlreadyVotedForThisPoll;
        },
        pollName() {
            return this.$store.getters.getCurrentPoll["code"];
        },
        pollTitle() {
            return this.$store.getters.getCurrentPoll["title"];
        },
        imageUrl() {
            return this.candidateData.artist_data.artist_image;
        }
    },
}
</script>

<style lang="scss">

.detail__fade {
    color: fade_out(white, .5);
}
</style>
