<template>
    <div class="form-panel">

        <JurySendAuthenticationScreen
                v-if="requestType === 'sendAuthenticationScreen'"
                :u="userName"
                :s="secret"
        />

        <JuryAutoLogin
                v-if="requestType === 'autoLogin'"
                :mode="mode"
                :oobCode="oobCode"
                :apiKey="apiKey"
                :lang="lang"
                :u="userName"
        />

    </div>
</template>

<script>
    import SvgIcon from "../shared/SvgIcon";
    import JurySendAuthenticationScreen from "./jury/JurySendAuthenticationScreen";
    import JuryAutoLogin from "./jury/JuryAutoLogin";

    export default {
        components: {JuryAutoLogin, JurySendAuthenticationScreen, SvgIcon},
        data() {
            return {
                requestType: null,
                userName: null,
                secret: null,
                mode: null,
                oobCode: null,
                apiKey: null,
                lang: null,
            }
        },
        async mounted() {
            const queryString = this.$route.query;
            this.userName = queryString.u || null;
            this.secret = queryString.secret || null;

            this.mode = queryString.mode || null;
            this.oobCode = queryString.oobCode || null;
            this.apiKey = queryString.apiKey || null;
            this.lang = queryString.lang || null;

            this.requestType = 'sendAuthenticationScreen';

            if (this.mode &&
                this.oobCode &&
                this.apiKey &&
                this.lang
            ) {
                this.requestType = 'autoLogin';
            }
        },
    }
</script>

<style lang="scss">
    .form-panel {
        max-width: 450px;
        margin: auto;
        label {
            font-size: 24px;
            &.checkbox {
                font-size: 12px;
                font-weight: normal;
                line-height: 20px;
            }
        }
        h2 {
            margin-bottom: 1em;
        }
    }

    .emailAddress {
        color: #dd3a60;
        font-size: 20px;
    }

    input {
        text-align: center;
    }
</style>


