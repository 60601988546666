import {FIREBASE_ERRORS} from "../../shared/firebaseErrors.it";
import {getFirebaseDb} from "../../shared/common";
import {finalizeJurorActivation, checkJurorPin} from "../../shared/juryApi";


const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');


export const onBoarding = {
    namespaced: true,
    state: {
        submitted: false,
        successMessage: '',
        isSubmitting: false,
        authErrors: [],
        model: {
            userId: '',
            username: '',
            firstName: '',
            lastName: '',
            title: '',
            pin: '',
            secret: '',
            password: '',
        }
    },
    getters: {
        form(state) {
            return state;
        },
        formErrors(state) {
            let errors = [];

            state.authErrors.forEach((err) => errors.push(err));

            if (state.model.username.length === 0) {
                errors.push("L'indirizzo e-mail non può essere vuoto");
            }
            const trimmedPassword = state.model.password.trim();
            if (trimmedPassword.length < 6) {
                errors.push("La password deve avere almeno 6 caratteri");
            }
            if (!(/^\d{4}$/).test(state.model.pin)) {
                errors.push("Il PIN deve essere composto da 4 cifre");
            }

            return errors;
        }
    },
    mutations: {
        setInitialState(state, payload) {
            if (payload.email) {
                state.model.username = payload.email;
            }
            if (payload.secret) {
                state.model.secret = payload.secret;
            }
            if (payload.userId) {
                state.model.userId = payload.userId;
            }
            if (payload.firstName) {
                state.model.firstName = payload.firstName;
            }
            if (payload.lastName) {
                state.model.lastName = payload.lastName;
            }
            if (payload.title) {
                state.model.title = payload.title;
            }
        },
        addAuthError(state, errorMessage) {
            state.authErrors.push(errorMessage)
        },
        resetAuthErrors(state) {
            state.authErrors = [];
        },
        prepareSubmit(state) {
            state.submitted = true;
            state.isSubmitting = true;
            state.successMessage = '';
            state.authErrors = [];
        },
        submitDone(state) {
            state.isSubmitting = false;
        },
        successMessage(state, message) {
            state.successMessage = message;
        }
    },
    actions: {
        async setJurorAsActive({commit, getters, dispatch}, {userId, secretCode}) {
            commit('prepareSubmit');
            commit('resetAuthErrors');
            if (getters.formErrors.length !== 0) {
                commit('submitDone')
            } else {

                commit('setTemporaryLogin', true, {root: true});
                // Pin validation
                const response = await checkJurorPin(
                    getters.form.model.userId,
                    getters.form.model.secret,
                    getters.form.model.pin,
                );
                /*
                    (response) => {
                        const pinValid = response.data && response.data.valid === true;
                        if (!pinValid) {
                            throw "Pin non valido o già attivato";
                        }

                        // User creation on firebase
                        return firebase.auth().createUserWithEmailAndPassword(
                            getters.form.model.username,
                            getters.form.model.password
                        );
                    })
                    .then((user) => {
                        newUser = user;

                        if (!user) {
                            throw "Impossibile creare l'utenza"
                        }

                        return user.updateProfile({
                            displayName: getters.form.model.firstName + " " + getters.form.model.lastName
                        });
                    })
                    .then(() => {
                        commit('setTemporaryLogin', false, {root: true});

                        // User finalization
                        const newJurorObject = {
                            userId: getters.form.model.userId,
                            firstName: getters.form.model.firstName,
                            lastName: getters.form.model.lastName,
                            title: getters.form.model.title,
                            secret: getters.form.model.secret,
                            pin: getters.form.model.pin,
                            firebaseUid: newUser.uid,
                        };
                        return finalizeJurorActivation(newJurorObject);
                    })
                    .then((response) => {
                        const success = response && response.data && response.data.success;
                        if (!success) {
                            throw "Impossibile finalizzare l'attivazione dell'utenza"
                        }

                        commit('submitDone');
                        commit('closeJuryOnboardScreen', null, {root: true});
                        dispatch('setUser', {
                            user: newUser,
                            isJuror: true
                        }, {root: true});
                    })
                    .catch((err) => {
                        commit('setError', err, {root: true});
                        commit('submitDone');
                    });

                */
            }
        }
    },
    getjurorData({commit}, {jurorId}) {
        return new Promise((resolve, reject) => {
            const fbDb = getFirebaseDb();
            const ref = fbDb.ref(process.env.VUE_APP_FIREBASE_AWARDS_BASE_PATH + "jury/list/").startAt(jurorId).endAt(jurorId);
            ref.once('value')
                .then((snapshot) => {
                    const value = snapshot.val();
                    resolve(value);
                })
        });
    },
    reset({commit, state, dispatch, getters}) {
        const resetPassword = state.resetPassword;
        commit('prepareSubmit');
        commit('resetAuthErrors');

        if (getters.formErrors.length === 0) {
            dispatch(
                'resetFirebasePassword',
                state.model.username
            )
        }
    }
};