<template>
    <div>
        <p class="big" v-if="candidateData === null">Ops! C'è stato un problema nel caricamento del candidato</p>
        <div v-if="candidateData !== null">
            <div class="sm:flex space-x-4">
                <div class="w-full sm:w-1/2">
                    <div class="sm:hidden mb-4 sm:mb-0 pt-12 sm:pt-0 text-center sm:text-left">
                        <h2 class="text-2xl font-black text-white uppercase">{{ candidateData.release_title }}</h2>
                        <h3 class="text-xl font-semibold uppercase">{{ candidateData.artist_name }}</h3>
                        <p class="text-sm opacity-25">{{ candidateData.label }}</p>
                    </div>

                    <div class="px-8">
                        <img :src="imageUrl" class="w-full ">
                    </div>

                    <div class="font-bold text-center mt-4 ">
                        CATEGORIA: {{ pollTitle }}
                    </div>

                    <div class="mt-4 flex items-center justify-center">
                        <vote-button-group
                            :pollName="pollName"
                            :itemId="itemId"
                            :itemCid="itemCid"
                            :cssClass="'candidate-controls--condensed'"
                            :elementIsVotable="elementIsVotable"
                            :votableDate="votableDate">
                        </vote-button-group>
                    </div>
                </div>

                <div class="sm:w-1/2">
                    <div class="hidden sm:block">
                        <h2 class="text-2xl font-black text-white uppercase">{{ candidateData.release_title }}</h2>
                        <h3 class="text-xl font-semibold uppercase">{{ candidateData.artist_name }}</h3>
                        <p class="text-sm opacity-25">{{ candidateData.label }}</p>
                    </div>

                    <div v-if="candidateData.tracklist" class="mt-8 sm:mt-4 text-sm opacity-90">
                        <h3 class="text-white">TRACKLIST</h3>
                        <ol class="opacity-80" v-for="(media,idx) in candidateData.tracklist" :key="idx">
                            <li v-for="track in media" :key="track.position">{{ track.title }}
                                <span v-if="track.length_pretty" class="tracklist__track-time">
                                    ({{ track.length_pretty }})
                                </span>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="my-8">
                <ad-unit ad-type="ad-lazy-load" :is-lazy-loaded="true" />
            </div>

            <div class="flex flex-col space-y-8 items-center justify-center mt-8 max-w-2xl mx-auto pb-8"
                 v-if="candidateData.review">
                <h2 class="font-black text-3xl">La recensione</h2>
                <div
                    v-html="candidateData.review"
                    class="body-text"
                    @click="outLink"></div>
            </div>
        </div>

    </div>
</template>

<script>
import VoteButtonGroup from '../buttons/VoteButtonGroup.vue';
import AdUnit from "../../shared/AdUnit";

export default {
    props: [
        "candidateData"
    ],
    components: {
        AdUnit,
        'vote-button-group': VoteButtonGroup,
    },
    computed: {
        itemId() {
            return this.candidateData.uid;
        },
        itemCid() {
            return this.candidateData.cid;
        },
        elementIsVotable() {
            const today = (new Date).getTime();
            let votableSince;
            if (this.candidateData.votable_since) {
                votableSince = this.getVotableDate(this.candidateData.votable_since).getTime();
            }

            return !this.candidateData.votable_since || today >= votableSince;
        },
        votableDate() {
            if (this.candidateData.votable_since) {
                let d = this.getVotableDate(this.candidateData.votable_since);
                const curr_date = d.getDate();
                const curr_month = d.getMonth() + 1; //Months are zero based
                return curr_date + "/" + curr_month;
            }
            return '';
        },
        userCanVote() {
            return !this.userAlreadyVoted;
        },
        userAlreadyVoted() {
            return this.$store.getters.hasAlreadyVotedForThisPoll;
        },
        pollName() {
            return this.$store.getters.getCurrentPoll["code"];
        },
        pollTitle() {
            return this.$store.getters.getCurrentPoll["title"];
        },
        imageUrl() {
            return this.candidateData.record_cover;
        }
    },
    methods: {
        getVotableDate(candidateDate) {
            const s = candidateDate;
            const a = s.split(/[^0-9]/);
            return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5])
        },
        outLink(e) {
            if (!e.target.hasAttribute("target")) {
                e.preventDefault();
                e.target.setAttribute("target", "_blank");
                e.target.setAttribute("rel", "noopener noreferrer");
                e.target.click();
                return;
            }

            return false;
        }
    }
}
</script>

<style lang="scss">

.detail__fade {
    color: fade_out(white, .5);
}
</style>
