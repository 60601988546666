var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('renderless-login-button',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var loginCheckInProgress = ref.loginCheckInProgress;
        var userInitials = ref.userInitials;
        var userLoggedIn = ref.userLoggedIn;
        var user = ref.user;
        var displayName = ref.displayName;
        var showLogin = ref.showLogin;
        var openUserPanel = ref.openUserPanel;
return _c('div',{},[(loginCheckInProgress)?_c('div',[_c('svg-icon',{staticStyle:{"opacity":".5"},attrs:{"icon":"loading","width":30,"height":30,"cssClass":"fill-current"}})],1):_vm._e(),(!loginCheckInProgress)?_c('div',[_vm._t("voteButton",function(){return [(!userLoggedIn)?_c('button',{on:{"click":function($event){$event.preventDefault();return showLogin.apply(null, arguments)}}},[_c('div',{staticClass:"px-6 py-2 border border-transparent text-base font-bold rounded-md text-white tracking-wide bg-current-theme opacity-90 hover:opacity-100 md:text-lg"},[_vm._v("Vota! ")])]):_vm._e()]},{"userLoggedIn":userLoggedIn,"showLogin":showLogin}),(userLoggedIn)?_c('div',{staticClass:"text-black w-12 h-12 rounded-full border-4 border-current-theme"},[_c('a',{staticClass:"flex items-center justify-center text-black w-full h-full rounded-full border-4 text-white",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return openUserPanel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(userInitials)+" ")])]):_vm._e()],2):_vm._e()])}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }