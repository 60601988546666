<template>
    <a href="#" class="vote-button"
       :class="{ disabled: !currentButton && voteInProgress }"
       @click.prevent="share(pollName, itemId, itemCid)">
        <div class="control-icon">

            <svg-icon icon="share-icon" width="20" height="20"></svg-icon>

        </div>
        <span class="control-text">Condividi</span>
    </a>
</template>

<script>
    import SvgIcon from '../../shared/SvgIcon.vue';

    import {
        pollDefinition
    } from '../../../shared/common'

    export default {
        props: ['pollName', 'itemId', 'itemCid'],
        data() {
            return {
                currentButton: false,
                helpMessage: '',
            }
        },
        computed: {
            voteInProgress() {
                return this.$store.getters.voteInProgress;
            }
        },
        components: {
            svgIcon: SvgIcon
        },
        methods: {
            share(pollName, itemId, itemCid) {
                this.$store.dispatch('showCandidateShareScreen', {
                    type: pollDefinition[pollName].candidateType,
                    id: itemId,
                    cid: itemCid
                });

                /*
                if (!this.voteInProgress) {
                    this.currentButton = true;
                    this.$store.dispatch('userVote', payload).then(() => {
                        this.currentButton = false;
                    });
                }
                */
            }
        }
    }
</script>
