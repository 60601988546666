import Vue from 'vue'
import App from './App.vue'
import {store} from './store'
import router from './router/index'
import SocialSharing from 'vue-social-sharing'
import Vuelidate from 'vuelidate'
import * as Integrations from '@sentry/integrations';

/** Import styles **/
import "./css/tailwind.css"

/*
|--------------------------------------------------------------------------
| Sentry
|--------------------------------------------------------------------------
*/
import * as Sentry from '@sentry/browser';


const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

Vue.use(Vuelidate);
Vue.use(SocialSharing);

window.firebaseMethodForSocialLogin = (process.env.VUE_APP_FIREBASE_SOCIAL_LOGIN_METHOD || null) === 'redirect'
    ? 'redirect'
    : 'popup';

firebase.initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
});

if (process.env.VUE_APP_SENTRY_DNS) {
    const conf = {
        dsn: process.env.VUE_APP_SENTRY_DNS,
        integrations: [new Integrations.Vue({Vue, attachProps: true})],
        /*
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            'Can\'t find variable: ZiteReader',
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage'
        ],
        */
        //'blacklistUrls': ['file:///']
    };
    Sentry.init(conf);
}

const vm = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    created() {
        this.$store.commit('setMobileSession', window.appConfiguration.isMobile);
    },
    mounted() {

    }
});

let adv_loaded = false;
vm.$on('start-adv', () => {
    if (!adv_loaded) {
        console.log('START ADV');
        injectAdvScripts();
    }
    adv_loaded = true;
})
vm.$on('poll-loaded', () => {
    console.log('POLL LOADED')
    window.refreshAllSlots();
})
vm.$on('show-login-panel', () => {
    console.log('SHOW LOGIN PANEL')
    window.loadLazyLoadedAds();
})
vm.$on('show-user-summary-panel', () => {
    console.log('SHOW USER SUMMARY PANEL')
    window.loadLazyLoadedAds();
})
vm.$on('show-share-candidate-panel', () => {
    console.log('SHOW SHARE CANDIDATE PANEL')
    window.loadLazyLoadedAds();
})
vm.$on('show-vote-complete-panel', () => {
    console.log('SHOW VOTE COMPLETE PANEL')
    window.loadLazyLoadedAds();
})
vm.$on('show-candidate-detail-panel', () => {
    console.log('SHOW CANDIDATE DETAIL PANEL')
    window.loadLazyLoadedAds();
})

firebase.auth()
    .onAuthStateChanged((firebaseUser) => {
        vm.$store.dispatch('autoSignIn', firebaseUser);
    });


function injectAdvScripts() {
    const scripts = [
        "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
        "https://cdn.exmarketplace.com/bidder/rockol/rockol_awards.dfp.js",
        "https://exmarketplace-com.videoplayerhub.com/videoplayer.js",
    ];

    scripts.forEach((url) => {
        dynamicallyLoadScript(url);
    })
}

window.refreshAllSlots = function () {
    return;
    if (typeof googletag !== 'undefined') {
        googletag.cmd.push(function () {
            googletag.pubads().clear();
            googletag.pubads().refresh();
        });
    }
};

window.loadLazyLoadedAds = function () {
    return;
    if (window.addSlots) {
        window.addSlots();
    } else {
        console.error('La funzione addSlots non è attiva')
    }
};

function reloadAdvScript() {
    return;
    setTimeout(() => {
        console.log('reload adv script');
        const scriptUrlToReload = 'https://cdn.exmarketplace.com/bidder/rockol/rockol.dfp.js';

        const scriptElementToRemove = document.querySelectorAll(`script`);

        scriptElementToRemove.forEach((scriptElement) => {
            if (scriptElement.src.indexOf(scriptUrlToReload) !== -1) {
                scriptElement.remove();
                const random = Math.random() * 100000;
                dynamicallyLoadScript(`${scriptUrlToReload}?cacheBust=${random}`);
            }
        });
    }, 300);
}

function dynamicallyLoadScript(url) {
    var script = document.createElement("script");  // create a script DOM node
    script.src = url;  // set its src to the provided URL
    script.async = false;  // set its src to the provided URL

    document.head.appendChild(script);
}

window.injectAdvScripts = injectAdvScripts;
window.reloadAdvScript = reloadAdvScript;

