<template>
    <modal-view @close-panel="closePanel" :open="showUserPanel" v-if="showUserPanel">
        <div>
            <div class="flex items-center justify-center text-xl" v-if="$store.state.user.displayName">
                <div>Ciao <strong>{{ $store.state.user.displayName }}</strong>!</div>
            </div>
            <div class="flex flex-col items-center justify-center">
                <div class="flex flex-wrap items-start">
                    <div class="w-full sm:w-1/2 flex flex-col justify-center items-center p-4 sm:p-8"
                         v-if="(pollsActive.indexOf(BEST_ITALIAN_RECORDS_POLL_NAME) !== -1)">
                        <h3 class="font-bold text-center text-2xl">Miglior album italiano</h3>
                        <vote-summary :pollName="BEST_ITALIAN_RECORDS_POLL_NAME"
                                      @close-panel="closePanel"></vote-summary>
                    </div>
                    <div class="w-full sm:w-1/2 flex flex-col justify-center items-center p-4 sm:p-8"
                         v-if="(pollsActive.indexOf(BEST_INTERNATIONAL_RECORDS_POLL_NAME) !== -1)">
                        <h3 class="font-bold text-center text-2xl">Miglior album internazionale</h3>
                        <vote-summary :pollName="BEST_INTERNATIONAL_RECORDS_POLL_NAME"
                                      @close-panel="closePanel"></vote-summary>
                    </div>
                    <div class="w-full sm:w-1/2 flex flex-col justify-center items-center p-4 sm:p-8"
                         v-if="(pollsActive.indexOf(BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME) !== -1)">
                        <h3 class="font-bold text-center text-2xl">Miglior artista live italiano</h3>
                        <vote-summary :pollName="BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME"
                                      @close-panel="closePanel"></vote-summary>
                    </div>
                    <div class="w-full sm:w-1/2 flex flex-col justify-center items-center p-4 sm:p-8"
                         v-if="(pollsActive.indexOf(BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME) !== -1)">
                        <h3 class="font-bold text-center text-2xl">Miglior artista live internazionale</h3>
                        <vote-summary :pollName="BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME"
                                      @close-panel="closePanel"></vote-summary>
                    </div>
                    <div class="row">
                        <div class="col-sm-12" v-if="(pollsActive.indexOf(BEST_VIDEO_POLL_NAME) !== -1)">
                            <h3 class="alt-text">Miglior video</h3>
                            <vote-summary :pollName="BEST_VIDEO_POLL_NAME"
                                          @close-panel="closePanel"></vote-summary>
                        </div>
                    </div>
                </div>

                <button @click.prevent="logOut" class="vote-button px-8 mt-8">Esci</button>

                <div class="my-8">
                    <ad-unit ad-type="ad-lazy-load" :is-lazy-loaded="true" />
                </div>

            </div>
        </div>
    </modal-view>
</template>

<script>
import VoteSummary from './VoteSummary.vue'
import {
    BEST_CREATORS_POLL_NAME,
    BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME,
    BEST_INTERNATIONAL_RECORDS_POLL_NAME,
    BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME,
    BEST_ITALIAN_RECORDS_POLL_NAME,
    BEST_VIDEO_POLL_NAME,
    getValidPolls,
} from '../../shared/common';
import ModalView from "../page-components/ModalView";
import AdUnit from "../shared/AdUnit";

export default {
    created: function () {
        const vm = this;
        window.addEventListener('keyup', function (e) {
            if (e.key === "Escape") {
                vm.$store.commit('closeUserPanel');
            }
        });
    },
    components: {
        AdUnit,
        ModalView,
        'vote-summary': VoteSummary
    },
    watch: {
        showUserPanel(show) {
            if (show) {
                this.$root.$emit('show-user-summary-panel');
            }
        }
    },
    data() {
        return {
            BEST_ITALIAN_RECORDS_POLL_NAME,
            BEST_INTERNATIONAL_RECORDS_POLL_NAME,
            BEST_INTERNATIONAL_ARTIST_SHOWS_POLL_NAME,
            BEST_ITALIAN_ARTIST_SHOWS_POLL_NAME,
            BEST_VIDEO_POLL_NAME,
            BEST_CREATORS_POLL_NAME,
            pollsActive: Object.keys(getValidPolls(process.env.VUE_APP_POLLS_SECTIONS || ''))
        }
    },
    computed: {
        showUserPanel() {
            return this.$store.getters.userPanel.show;
        },
        votes() {
            return this.$store.getters.userVoteState.votes;
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch("userSignOut");
        },
        closePanel() {
            this.$store.commit("closeUserPanel");
        }
    }
}
</script>
