import User from '../shared/User'
import {
    bodyScroll, getValidPolls,
    userIsJuror,
} from '../shared/common'

const validPolls = getValidPolls(process.env.VUE_APP_POLLS_SECTIONS || '');

const userVoteStructure = Object.keys(validPolls).reduce((acc, key) => {
    acc[key] = {};
    return acc;
}, {});
const pollsStillToBeVoted = Object.keys(validPolls);

export const mutations = {
    setUser(state, {user = null, profile = {}, consents = {}}) {
        state.userPanel.show = false;
        if (user === null) {
            state.userLoggedIn = false;
            state.user = null
        } else {
            state.userLoggedIn = true;
            state.user = Object.assign({}, new User(user, profile, consents));
        }
        state.loginCheckInProgress = false;
    },
    setUserConsents(state, consents) {
        if (this.state.user) {
            this.state.user.consents = consents;
        }
    },
    addConsent(state, consent) {
        state.user.consents = Object.assign(consent, state.user.consents);
    },
    setMobileSession(state, payload) {
        state.app.isMobile = payload
    },
    setIsJurorAttribute(state, isJuror) {
        if (state.user) {
            state.user = Object.assign(state.user, {isJuror: !!isJuror});
        }
    },
    setTemporaryLogin(state, value) {
        state.temporaryLogin = !!value
    },
    resetUserStatus(state) {
        state.userLoggedIn = false;
        state.user = {
            profile: {},
            consents: {}
        };
        state.userVoteState.nrVotes = 0;
        state.userVoteState.pollsVoted = [];
        state.userVoteState.votes = userVoteStructure;
        state.userVoteState.pollsStillToBeVoted = pollsStillToBeVoted;
    },
    voteInProgress(state, value) {
        state.voteInProgress = value;
    },
    setVoteAfterLogin(state, payload) {
        if (window.sessionStorage){
            if (payload !== null) {
                window.sessionStorage.setItem('AwardsVoteAfterLogin', JSON.stringify(payload))
            } else {
                window.sessionStorage.removeItem('AwardsVoteAfterLogin')
            }
        }
        state.voteAfterLogin = payload;
    },
    setPollAsVoted(state, {pollName, candidateData}) {
        const tempState = JSON.parse(JSON.stringify(state.userVoteState));
        const index = tempState.pollsStillToBeVoted.indexOf(pollName);
        if (index !== -1) {
            tempState.nrVotes++;
            tempState.pollsStillToBeVoted.splice(index, 1);
            tempState.pollsVoted.push(pollName);
            tempState.votes[pollName].data = candidateData;
        }
        state.userVoteState = Object.assign(state.userVoteState, tempState);
    },
    setError(state, payload) {
        state.error = payload
    },
    setCurrentPoll(state, payload) {
        state.currentPoll.code = payload.code;
        state.currentPoll.title = payload.title;
    },
    setLoading(state, payload) {
        state.loading = payload
    },
    loggingIn(state, loggingIn) {
        state.loggingIn = loggingIn
    },

    // LOGIN PANEL
    showLoginPanel(state) {
        bodyScroll(false);
        state.loginPanel.show = true;
    },
    closeLoginPanel(state) {
        const otherPanelOpened = state.userPanel.show === true
            || state.votePanel.show === true
            || state.candidateDetailPanel.show === true;
        const makeBodyVisible = otherPanelOpened === false;
        bodyScroll(true, makeBodyVisible);
        state.loginPanel.show = false;
    },

    // SHARE PANEL
    showSharePanel(state) {
        bodyScroll(false);
        state.sharePanel.show = true;
    },
    setSharePanelInLoadingState(state, loadingState) {
        state.sharePanel.loading = loadingState;
    },
    setShareCandidateDetail(state, payload) {
        state.sharePanel.candidateType = payload.type;
        state.sharePanel.candidateData = payload.data;
    },
    closeSharePanel(state) {
        const otherPanelOpened = state.loginPanel.show === true
            || state.userPanel.show === true
            || state.votePanel.show === true
            || state.candidateDetailPanel.show === true;
        const makeBodyVisible = otherPanelOpened === false;
        bodyScroll(true, makeBodyVisible);
        state.sharePanel.show = false;
    },

    // USER SUMMARY PANEL
    showUserPanel(state) {
        bodyScroll(false);
        state.userPanel.show = true;
    },
    closeUserPanel(state) {
        bodyScroll(true);
        state.userPanel.show = false;
    },

    // CANDIDATE PANEL
    setCandidateDetail(state, payload) {
        state.candidateDetailPanel.candidateType = payload.type;
        state.candidateDetailPanel.candidateData = payload.data;
    },
    showCandidatePanel(state) {
        bodyScroll(false);
        state.candidateDetailPanel.show = true;
    },
    setCandidatePanelInLoadingState(state, loadingState) {
        state.candidateDetailPanel.loading = loadingState;
    },
    closeCandidatePanel(state) {
        bodyScroll(true);
        state.candidateDetailPanel.show = false;
    },

    // VOTE PANEL
    showVotePanel(state) {
        bodyScroll(false);
        state.votePanel.show = true;
    },
    closeVotePanel(state) {
        bodyScroll(true);
        state.votePanel.show = false;
    },

    // JURY LOGIN SCREEN PANEL
    showJuryLoginScreen(state) {
        bodyScroll(false);
        state.juryLoginScreen.show = true;
    },
    closeJuryLoginScreen(state) {
        bodyScroll(true);
        state.juryLoginScreen.show = false;
    },

    // JURY ONBOARDING SCREEN PANEL
    showJuryOnboardScreen(state) {
        bodyScroll(false);
        state.juryOnboardScreen.show = true;
    },
    closeJuryOnboardScreen(state) {
        bodyScroll(true);
        state.juryOnboardScreen.show = false;
    },
    refreshAdv() {
        window.reloadAdvScript();
    }
};
