<template>
    <a href="" @click.prevent="closeCallback">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             class="fill-current"
             width="30" height="30" x="0px" y="0px"
             viewBox="0 0 1000 1000"
             enable-background="new 0 0 1000 1000"
             xml:space="preserve">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><path d="M990,928.8L561.3,500L990,71.3L928.8,10L500,438.8L71.3,10L10,71.3L438.8,500L10,928.8L71.3,990L500,561.3L928.8,990L990,928.8z"/></g>
            </svg>
    </a>

</template>

<script>
    export default {
        props: ['onClose'],
        methods: {
            closeCallback() {
                this.onClose();
            }
        }
    }
</script>
