import * as FirebaseRepository from '../shared/FirebaseRepository.js'
import {getCandidateDetailFromApi, getCandidateType} from '../shared/common'
import {
    activateJurorFromExistingUser,
    checkIfUserIsRegisteredWithPasswordProvider,
    getJurorByEmail,
    getJurorById
} from '../shared/juryApi'
import {FIREBASE_ERRORS} from '../shared/firebaseErrors.it'
import sendErrorToSentry from "../shared/errorReporting";

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

export const actions = {
    async userSocialLogin({commit, dispatch, state, getters}, payload) {
        commit('setError', null);
        if (!getters.isVoteOpenForUsers) {
            commit('setError', 'Le votazioni per la giuria sono chiuse');
            await dispatch('userSignOut');
            return;
        }
        firebase.auth().languageCode = 'it_IT';
        let provider = null;
        switch (payload.provider) {
            case "facebook":
                provider = new firebase.auth.FacebookAuthProvider();
                break;
            case "google":
                provider = new firebase.auth.GoogleAuthProvider();
                break;
            case "twitter":
                provider = new firebase.auth.TwitterAuthProvider();
                break;
            default:
                return;
        }
        provider.setCustomParameters({
            'display': 'popup'
        });

        commit('loggingIn', true);
        let userLoggedInData = null;
        let errMessage = null;

        /*
        if (!userLoggedInData) {
            try {
                userLoggedInData = await firebase.auth().signInWithPopup(provider);
            } catch (error) {
                errMessage = FIREBASE_ERRORS[error.code] || "Impossibile collegarsi con il provider per l'autenticazione";
            }
        }
        */

        if (!userLoggedInData) {
            try {
                if (window.firebaseMethodForSocialLogin === 'redirect') {
                    userLoggedInData = await firebase.auth().signInWithRedirect(provider);
                } else {
                    userLoggedInData = await firebase.auth().signInWithPopup(provider);
                }

            } catch (error) {
                console.error(error);
                errMessage = FIREBASE_ERRORS[error.code] || "Impossibile collegarsi con il provider per l'autenticazione";
            }
        }


        if (userLoggedInData) {
            try {
                const uid = userLoggedInData.user.uid;

                // We save the needed consent if none available
                let consents = await FirebaseRepository.getUserValidConsents(uid);
                if (!consents.privacy) {
                    await FirebaseRepository.addFirebaseConsent(uid, 'auth_privacy', 'opt-in');
                }
                if (!consents.awards) {
                    await FirebaseRepository.addFirebaseConsent(uid, 'auth_awards', 'opt-in');
                }

                const userCompleteProfile = await FirebaseRepository.getCompleteUserProfile(uid);

                dispatch(
                    'populateUserDataWithVotes',
                    {
                        userData: userLoggedInData,
                        savedData: userCompleteProfile
                    }
                );

                dispatch('userAutoVote');

            } catch (error) {
                userLoggedInData = null;
                console.error(error);
                const errMessage = FIREBASE_ERRORS[error.code] || "C'è stato un errore di rete";
                commit('setError', errMessage)
            } finally {
                commit('closeLoginPanel')
            }
        } else {
            commit('setError', errMessage)
        }

        commit('loggingIn', false);
    },
    async loginWithUsernameAndPassword({commit, dispatch, getters}, {email = '', password = ''}) {
        commit('setError', null);
        commit('loggingIn', true);

        let firebaseUser = null;
        let firebaseError;
        try {
            firebaseUser = await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (error) {
            sendErrorToSentry(firebaseError);
            firebaseError = error;
        }

        if (!getters.isVoteOpenForUsers) {
            commit('setError', 'Le votazioni non sono aperte');
            await dispatch('userSignOut');
            commit('loggingIn', false);
            return Promise.reject({
                error: true,
                publicMessage: 'Le votazioni non sono aperte'
            });
        }

        if (!firebaseUser) {
            return Promise.reject({
                error: true,
                publicMessage: FIREBASE_ERRORS[firebaseError.code] || 'Impossibile effettuare l\'accesso'
            })
        }

        const uid = firebaseUser.user.uid;

        const userCompleteProfile = await FirebaseRepository.getCompleteUserProfile(uid);

        await dispatch(
            'populateUserDataWithVotes',
            {
                userData: firebaseUser.user,
                savedData: userCompleteProfile
            }
        );

        dispatch('userAutoVote');

        commit('setLoading', false);
        commit('setError', null);
        commit('loggingIn', false);

    },
    async userAutoVote({state, commit, dispatch}) {
        if (state.voteAfterLogin) {
            // Check if user hase already voted
            if (state.userVoteState.pollsStillToBeVoted.indexOf(state.voteAfterLogin.pollName) !== -1) {
                await dispatch('userVote', state.voteAfterLogin);
            } else {
                commit('setError', "Spiacente, hai già votato per questa categoria");
            }
            commit('setVoteAfterLogin', null)
        }
    },
    async addUserConsents({commit}, uid) {
        let consents = await FirebaseRepository.getUserValidConsents(uid);
        if (!consents.privacy) {
            await FirebaseRepository.addFirebaseConsent(uid, 'auth_privacy', 'opt-in');
        }
        if (!consents.awards) {
            await FirebaseRepository.addFirebaseConsent(uid, 'auth_awards', 'opt-in');
        }
        consents = await FirebaseRepository.getUserValidConsents(uid);
        commit('setUserConsents', consents);
    },
    async loginJurorThroughEmail({commit, dispatch, getters}, {email = '', href = ''}) {
        commit('setError', null);
        commit('loggingIn', true);


        let firebaseUser = null;
        try {
            firebaseUser = await firebase.auth().signInWithEmailLink(email, href);
        } catch (err) {
            sendErrorToSentry(err);
            console.error(err);
        }

        if (!getters.isVoteOpenForJury) {
            commit('setError', 'Le votazioni per la giuria sono chiuse');
            await dispatch('userSignOut');
            commit('loggingIn', false);
            return Promise.reject({
                error: true,
                publicMessage: 'Le votazioni per la giuria sono chiuse'
            });
        }

        if (!firebaseUser) {
            return Promise.reject({
                error: true,
                publicMessage: 'Impossibile effettuare l\'autenticazione'
            })
        }

        const uid = firebaseUser.user.uid;

        const jurorData = await getJurorByEmail(email);
        await activateJurorFromExistingUser({
            email: email,
            firebaseUid: uid
        });

        await FirebaseRepository.saveUserProfile(uid, {
            firstName: jurorData.data.firstName,
            lastName: jurorData.data.lastName,
            email: jurorData.data.email,
        });

        const userCompleteProfile = await FirebaseRepository.getCompleteUserProfile(uid);

        await dispatch(
            'populateUserDataWithVotes',
            {
                userData: firebaseUser.user,
                savedData: userCompleteProfile
            }
        );

        commit('setIsJurorAttribute', true);
        commit('setLoading', false);
        commit('setError', null);
        commit('loggingIn', false);

    },
    async userSignOut({dispatch}) {
        dispatch('populateUserDataWithVotes', {});
        await firebase.auth().signOut();
        dispatch('closeAllModals');
    },
    autoSignIn: async function ({commit, dispatch, getters, state}, authData) {
        if (state.loggingIn) {
            return;
        }

        if (authData === null) {
            await dispatch('populateUserDataWithVotes', {});
            return;
        }

        const userCompleteProfile = await FirebaseRepository.getCompleteUserProfile(authData.uid);

        if (!getters.isVoteOpenForUsers) {
            //commit('setError', 'Le votazioni sono chiuse');
            //await dispatch('userSignOut');

            commit('setUser', {
                user: null,
                profile: {},
                consents: {}
            });
            return;
        }

        await dispatch('populateUserDataWithVotes', {
            userData: authData,
            savedData: userCompleteProfile
        });

        dispatch('userAutoVote');
    },
    async populateUserDataWithVotes({commit}, {userData = null, savedData = {}}) {
        commit('resetUserStatus');

        if (userData === null) {
            await commit('setUser', {});
            commit('setVoteAfterLogin', null);
            return;
        }

        commit('setUser', {
            user: userData,
            profile: savedData.profile || {},
            consents: savedData.consents || {}
        });

        commit('setUserConsents', savedData.consents);

        if (savedData && savedData.votes) {
            Object.values(savedData.votes).forEach((pollStatus) => {
                commit('setPollAsVoted', {
                    pollName: pollStatus.pollName,
                    candidateData: pollStatus.candidateData
                });
            })
        }
    },
    closeAllModals({commit}) {
        const panelsToClose = [
            'closeLoginPanel',
            'closeUserPanel',
            'closeCandidatePanel',
            'closeUserPanel',
            'closeVotePanel',
        ];
        panelsToClose.forEach((mutationName) => {
            commit(mutationName);
        });
    },
    async setupAutoVoteAfterLogin({commit}, {pollName, itemId}) {
        const candidateType = getCandidateType(pollName);
        commit("showLoginPanel");

        const res = await getCandidateDetailFromApi(candidateType, itemId);
        commit('setVoteAfterLogin', {
            pollName,
            itemId,
            candidateType: candidateType,
            candidateData: res.data
        })
    },
    async userVote(context, {pollName, itemId}) {
        const commit = context.commit;
        const uid = context.getters.getUser.uid || null;
        commit('voteInProgress', true);

        if (uid) {
            try {
                const candidateData = await FirebaseRepository.saveUserVoteToFirebase(pollName, uid, itemId);
                commit('voteInProgress', false);
                commit('setPollAsVoted', {pollName, candidateData});
                commit('showVotePanel', true);
                commit('setVoteAfterLogin', null);
                return true;
            } catch (error) {
                sendErrorToSentry(error);
                console.error(error);
                commit('setError', "Errore durante il salvataggio.");
                commit('voteInProgress', false);
            }
        } else {
            return false;
            //commit('voteInProgress', true);
        }

    },
    showCandidateDetails({commit}, payload) {
        commit('setCandidatePanelInLoadingState', true);
        commit('showCandidatePanel');
        getCandidateDetailFromApi(payload.type, payload.id)
            .then((res) => {
                commit('setCandidatePanelInLoadingState', false);

                const data = res.data;
                commit('setCandidateDetail', {type: payload.type, data});
            })
            .catch((err) => {
                sendErrorToSentry(err);
                console.error(err);
                commit('setCandidatePanelInLoadingState', false);
                commit('setError', 'C\'è stato un errore nel caricamento del candidato');
            })
    },
    showCandidateShareScreen({commit}, payload) {
        commit('setSharePanelInLoadingState', true);
        commit('showSharePanel');
        getCandidateDetailFromApi(payload.type, payload.id)
            .then((res) => {
                commit('setSharePanelInLoadingState', false);

                const data = res.data;
                data.cid = payload.cid;
                commit('setShareCandidateDetail', {type: payload.type, data});
            })
            .catch((err) => {
                sendErrorToSentry(err);
                console.error(err);
                commit('setSharePanelInLoadingState', false);
                commit('setError', 'C\'è stato un errore nel caricamento del candidato');
            })
    },
    startJurorOnBoarding({commit, getters}, {uid, secret}) {
        if (getters.userLoggedIn) {
            return;
        }

        // Recupero il giurato in base all'id
        let jurorObject;
        getJurorById(uid)
            .then((result) => {
                if (!result.data) {
                    throw "Nessun giurato trovato rispetto ai parametri passati"
                }

                // Controllo se il pin è attivato
                jurorObject = result.data;
                if (jurorObject.pin_activated) {
                    return true;
                } else {
                    return checkIfUserIsRegisteredWithPasswordProvider(jurorObject.email)
                }
            })
            .then((alreadyRegistered) => {

                // Pin non attivato -> mostro l'onboarding screen
                jurorObject.secret = secret;
                jurorObject.userId = uid;
                commit('jury/onBoarding/setInitialState', jurorObject);
                commit('showJuryOnboardScreen')

            })
            .catch((error) => {
                sendErrorToSentry(error);
                console.error(error);
                commit("setError", "C'è stato un errore: " + error)
            })
        // Controllo che il giurato sia o meno un nostro utente

        // Se lo è allora procedo con la visualizzazione della login (con onboarding)

        // Se non lo è allora procedo con la visualizzazione dell'onboarding completo
    }

};
