import {getCandidateDetailFromApi, getFirebaseDb, getCandidateType, pollDefinition} from "./common";
import firebase from 'firebase/app';
import axios from "axios";
import sendErrorToSentry from "./errorReporting";

const USERS_REF = '/users/';
const USERS_CONSENT_REF = '/users-consent/';
const POLL_BASE_URL = process.env.VUE_APP_FIREBASE_AWARDS_BASE_PATH;

/*
|--------------------------------------------------------------------------
| User Profile
|--------------------------------------------------------------------------
*/
async function getUserProfile(uid) {
    const profileData = await getDataFromFirebase(USERS_REF + uid);
    return (profileData || {}).profile || null;
}

async function saveUserProfile(uid, profileData) {
    const db = getFirebaseDb();
    const userRef = USERS_REF + uid + '/';
    await db.ref(userRef).update({
        account_created: firebase.database.ServerValue.TIMESTAMP,
        profile: profileData
    });
}


/*
|--------------------------------------------------------------------------
| POLL VOTES
|--------------------------------------------------------------------------
*/
async function getUserVoteForSinglePoll(pollName, uid, userType = 'user') {
    const candidateType = getCandidateType(pollName);
    const userTypeKey = userType === "user" ? 'users' : 'jury';
    const userVoteRef = POLL_BASE_URL + "polls/" + pollName + "/votes/" + userTypeKey + "/records/" + uid;
    const voteObject = await getDataFromFirebase(userVoteRef);

    if (voteObject === null) {
        return null;
    }

    const res = await getCandidateDetailFromApi(candidateType, voteObject.itemId);

    let candidateData = null;
    if (res && res.data) {
        candidateData = {
            pollName: pollName,
            data: res.data
        }
    }

    return candidateData;
}
async function saveUserVoteToFirebase(pollName, uid, itemId) {
    const fbDb = getFirebaseDb();
    const userVoteRef = fbDb.ref(POLL_BASE_URL + "polls/" + pollName + "/votes/users/records/" + uid);
    const userVoteCountRef = fbDb.ref(POLL_BASE_URL + "polls/" + pollName + "/votes/users/counts/").child(itemId);

    try {
        await userVoteRef.set({
            "itemId": itemId,
            "ts": firebase.database.ServerValue.TIMESTAMP
        });
        await userVoteCountRef.transaction((count) => (count || 0) + 1);
        const recordType = pollDefinition[pollName].candidateType;
        const votedCandidate = await getCandidateDetailFromApi(recordType, itemId);
        return votedCandidate.data;
    } catch (err) {
        sendErrorToSentry(err);
        console.error(err);
        return Promise.reject(err);
    }
}
/*
|--------------------------------------------------------------------------
| USERS CONSENTS
|--------------------------------------------------------------------------
*/
async function getUserValidConsents(uid) {
    return extractValidConsentsFromHistory(await getFirebaseUserConsentsHistory(uid));
}
async function getFirebaseUserConsentsHistory(uid) {
    try {
        return getDataFromFirebase(USERS_CONSENT_REF + uid);
    } catch (err) {
        sendErrorToSentry(err);
        console.error(err);
        return Promise.reject(err);
    }
}

function extractValidConsentsFromHistory(consentsFirebaseValue) {
    const consentsArray = consentsFirebaseValue ? Object.values(consentsFirebaseValue) : [];
    const consents = consentsArray.reduce((result, curr) => {
        const map = {
            auth_privacy: 'privacy',
            auth_awards: 'awards',
        };
        if (map[curr.consent_name]) {
            result[map[curr.consent_name]].push(curr);
        }
        return result;
    }, {
        'privacy': [],
        'awards': [],
    });
    const sortConsents = (a, b) => {
        if (a.ts > b.ts)
            return -1;
        if (a.ts < b.ts)
            return 1;
        return 0;
    };
    consents['privacy'] = (consents['privacy'].sort(sortConsents).shift() || {}).consent_type === 'opt-in';
    consents['awards'] = (consents['awards'].sort(sortConsents).shift() || {}).consent_type === 'opt-in';
    return consents;
}

async function addFirebaseConsent(uid, consent_name, consent_type) {
    const db = getFirebaseDb();
    try {
        const consentObject = {
            consent_name,
            consent_type,
            ts: firebase.database.ServerValue.TIMESTAMP,
        };
        const userConsentRef = USERS_CONSENT_REF + uid;
        await db.ref(userConsentRef).push(consentObject);
    } catch (err) {
        sendErrorToSentry(err);
        console.error(err);
        return Promise.reject(err);
    }
}

async function getDataFromFirebase(ref) {
    const db = getFirebaseDb();
    const snapshot = await db.ref(ref).once('value');
    return snapshot.val();
}

async function getUserVotes(uid) {
    const votedPolls = {};

    const promises = [];

    Object.keys(pollDefinition).forEach(pollName => {
        promises.push(getUserVoteForSinglePoll(pollName, uid));
    });

    const responses = await axios.all(promises);
    responses.forEach((pollStatus) => {
        if (pollStatus && pollStatus.pollName && pollStatus.data) {
            votedPolls[pollStatus.pollName] = {
                pollName: pollStatus.pollName,
                candidateData: pollStatus.data
            };
        }
    });

    return votedPolls;
}

async function getCompleteUserProfile(uid) {

    const profile = await getUserProfile(uid);
    const consents = await getUserValidConsents(uid);
    const votes = await getUserVotes(uid);

    return {
        profile,
        consents,
        votes
    }
}

/*
|--------------------------------------------------------------------------
| EXPORTS
|--------------------------------------------------------------------------
*/
export {
    getUserProfile,
    saveUserProfile,
    getUserVoteForSinglePoll,
    saveUserVoteToFirebase,
    getUserValidConsents,
    addFirebaseConsent,
    getCompleteUserProfile
}
