<template>
    <a :is="tagType" href="#" v-if="!userAlreadyVoted"
       class="vote-button"
       :class="{ disabled: otherButtonIsVoting }"
       @click.prevent="vote({pollName, itemId})">
        <div class="control-icon">
            <svg-icon icon="vote-icon" width="20" height="20" v-if="!currentButtonIsVoting"></svg-icon>
            <svg-icon icon="loading" width="20" height="20" v-if="currentButtonIsVoting"></svg-icon>
        </div>
        <span class="control-text">Vota</span>
    </a>
</template>

<script>
    import SvgIcon from '../../shared/SvgIcon.vue';

    export default {
        props: ['pollName', 'itemId'],
        data() {
            return {
                currentButton: false,
            }
        },
        components: {
            svgIcon: SvgIcon
        },
        computed: {
            tagType() {
                if (this.voteInProgress) {
                    return 'span'
                } else {
                    return 'a'
                }
            },
            loggedIn() {
                return this.$store.getters.userLoggedIn;
            },
            userAlreadyVoted() {
                return this.$store.getters.hasAlreadyVotedForThisPoll;
            },
            currentButtonIsVoting() {
                return this.currentButton && this.voteInProgress
            },
            otherButtonIsVoting() {
                return !this.currentButton && this.voteInProgress
            },
            voteInProgress() {
                return this.$store.getters.voteInProgress;
            }
        },
        methods: {
            async vote(payload) {
                if (!this.loggedIn) {
                    await this.$store.dispatch('setupAutoVoteAfterLogin', payload);
                    return;
                }

                if (!this.voteInProgress) {
                    this.currentButton = true;
                    await this.$store.dispatch('userVote', payload);
                    this.currentButton = false;
                }
            }
        }
    }
</script>
