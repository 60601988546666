<template>
    <div id="app" class="h-full" v-cloak>
        <router-view id="page-view" class="h-full" :key="$route.fullPath"></router-view>

        <error-message></error-message>

        <candidate-detail-screen></candidate-detail-screen>
        <share-candidate-screen></share-candidate-screen>
        <completed-vote-screen></completed-vote-screen>
        <login-screen></login-screen>
        <user-summary-screen></user-summary-screen>
        <user-permission-screen></user-permission-screen>
    </div>
</template>


<script>
import LoginScreen from './components/user/LoginScreen.vue';
import UserSummaryScreen from './components/user/UserSummaryScreen.vue';
import UserPermissionScreen from './components/user/UserPermissionScreen.vue';
import CompletedVoteScreen from './components/candidates/screens/CompletedVoteScreen.vue';
import CandidateDetailScreen from './components/candidates/screens/CandidateDetailScreen.vue';
import ShareCandidateScreen from './components/candidates/screens/ShareCandidateScreen.vue';
import ErrorMessage from './components/Error.vue';
import {mapGetters} from 'vuex'

export default {
    name: 'app',
    components: {
        loginScreen: LoginScreen,
        userSummaryScreen: UserSummaryScreen,
        candidateDetailScreen: CandidateDetailScreen,
        completedVoteScreen: CompletedVoteScreen,
        shareCandidateScreen: ShareCandidateScreen,
        UserPermissionScreen,
        errorMessage: ErrorMessage,
    },
    data() {
        return {
            onBoardChecked: false
        }
    },
    watch: {
        loginCheckInProgress(checked) {
            if (checked === false && this.onBoardChecked === false) {
                this.onBoardChecked = true;
            }
        },
        $route() {
            this.$store.commit('setError', null);
        }
    },
    computed: {
        ...mapGetters([
            'userLoggedIn',
            'loginCheckInProgress'
        ]),
    },
}
</script>
